import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientHeader from "../Dashboard/ClientHeader";
import { Row } from "react-bootstrap";
import { Card, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import SearchOption from "../../components/Common/SearchOption";
import Select from "react-select";
import excel from "../../assets/svg-new/corridor-report.svg";
import reportFile from "../../assets/svg-new/reportUpload.png";
import imgScreen from "../../assets/svg-new/screensot-img.svg";
import towerPdf from "../../assets/svg-new/pdf-icon.svg";
import homeicon from "../../assets/svg-new/home-icon.svg";
import { healthOptions, severityOptions } from "../Components/Constants";
import * as gapi from "../../api/global.api";
import DataTable from "react-data-table-component";
import { base64ToBlob, getDateOnly } from "../Components/Utils";
import Loader from "../../components/Common/Loader";
import PaginationComponent from "../Components/PaginationComponent";
import { xlReportDownload } from "../../api/dashboard.api";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import fileimg from "../../assets/svg-new/pdf-icon.svg";
import { towerColumns } from "../../components/tables/Schema/TowerSchema";

const TxTowers = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [records, setRecords] = useState([]);
  const [filter, setFilter] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [isLightbox, setIsLightbox] = useState(false);
  const [idx, setIdx] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  const [thermalRecords, setThermalRecords] = useState([]);
  const [isThermal, setIsThermal] = useState(false);

  const [statusOptions, setStatusOptions] = useState([]);

  const [column, setColumn] = useState([]);
  const [newColumn, setNewColumn] = useState([]);

  const getTowers = async () => {
    setLoading(true);
    let res = await gapi.get(
      // `getTower?corridorId=${location.corridor.id}&isActive=true&fileType=client_screenshot`
      `getTower?corridorId=${location.corridor.id}&fileType=client_screenshot`
    );
    if (res?.severityStatuses) {
      const dynamicStatusOptions = res?.severityStatuses?.map((status) => ({
        label: status,
        value: status,
      }));
      setStatusOptions(dynamicStatusOptions);
    } else {
      setStatusOptions([]);
    }

    setRecords(res?.response?.rows);
    setFilter(res?.response?.rows);
    if (res?.response?.allowedColoumns?.length > 0) {
      let filterData = res?.response?.allowedColoumns?.filter((e) => e.checked);
      setColumn(filterData);
    } else {
      setColumn(towerColumns);
    }
    setLoading(false);
  };

  const getTower2 = async (health) => {
    setLoading(true);
    let url =
      health === "all"
        ? `getTower?projectId=${location.project.id}&isActive=true&fileType=client_screenshot`
        : `getTower?projectId=${location.project.id}&severityStatus=${health}&isActive=true&fileType=client_screenshot`;
    let res = await gapi.get(url);
    if (res?.severityStatuses) {
      const dynamicStatusOptions = res?.severityStatuses?.map((status) => ({
        label: status,
        value: status,
      }));
      setStatusOptions(dynamicStatusOptions);
    } else {
      setStatusOptions([]);
    }
    setRecords(res?.response?.rows);
    setFilter(res?.response?.rows);
    if (res?.response?.allowedColoumns?.length > 0) {
      let filterData = res?.response?.allowedColoumns?.filter((e) => e.checked);
      setColumn(filterData);
    } else {
      setColumn(towerColumns);
    }
    setLoading(false);
  };

  const handleNavigateToGoogle = (location) => {
    let loc = location.split(",");
    var a = document.createElement("a");
    a.href = `https://www.google.com/maps?q=${loc[1]},${[loc[0]]}`;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSelectFilter = (val) => {
    if (val !== null) {
      setRecords([]);
      setSelected(val);
    } else {
      setSelected(null);
      setRecords([]);
    }
  };

  const corridorReport = async () => {
    var a = document.createElement("a");
    const response = await xlReportDownload({
      id: location?.corridor?.id,
      clientId: location?.project?.clientId,
      clientName: location?.project?.client?.clientName,
      projectId: location?.project?.id,
      projectName: location?.project?.projectName,
      sourceId: location?.corridor?.sourcesId?.id,
      sourceName: location?.corridor?.sources?.sourceName,
      isClient: true,
    });
    if (!response) {
      toast.error(
        "No completed towers available to download the corridor report"
      );
      return;
    }
    a.href = response;
    a.download = response?.split("@consolidatedReport/")[1];
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onReportClick = (row) => {
    setLoading(true);
    let ele;
    if (
      row.towerReport?.includes("http://") ||
      row.towerReport?.includes("data:") ||
      row.towerReport?.includes("https://")
    ) {
      ele = row.towerReport;
    } else {
      ele = process.env.REACT_APP_API_URL + "view/" + row.towerReport;
    }

    if (ele) {
      const link = document.createElement("a");
      link.href = ele;
      link.target = "_blank";
      link.download = ele.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("Reports not generated yet");
    }
    setLoading(false);
  };

  const onThermalReportClick = async (row) => {
    setLoading(true);
    let reports = await api.getData(
      `getThermalReportFiles?id=${row?.id}&fileType=report`
    );
    if (reports?.data?.uploadJsonData?.length > 0) {
      setThermalRecords(reports?.data?.uploadJsonData);
      setIsThermal(true);
    } else {
      toast.error("No Thermal reports to display");
    }
    setLoading(false);
  };

  const handleDownloadReport = (record) => {
    const url =
      process.env.REACT_APP_UPLOAD_TYPE === "local"
        ? process.env.REACT_APP_API_URL + "view/" + record?.defectFile
        : record.defectFile;

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = `${record?.fileName ? record?.fileName : "report.pdf"}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    location.corridor ? getTowers() : getTower2(location.health);
    location?.health &&
      location?.health !== "all" &&
      setSelected({ label: location?.health, value: location?.health });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let search = searchTerm.toLowerCase();
      if (searchTerm === "" && selected === null && records?.length > 0) {
        setFilter(records);
      } else if (
        searchTerm !== "" &&
        selected === null &&
        records?.length > 0
      ) {
        let res = records.filter(
          (item) =>
            item.towerNo.toLowerCase().includes(search) ||
            item.lineName.toLowerCase().includes(search)
        );
        setFilter(res);
      } else if (
        searchTerm !== "" &&
        selected !== null &&
        records?.length > 0
      ) {
        let res = records.filter(
          (item) =>
            item.towerNo.toLowerCase().includes(search) ||
            item.lineName.toLowerCase().includes(search)
        );
        let fData = res.filter((e) => e.severityStatus === selected.value);
        setFilter(fData);
      } else if (
        searchTerm === "" &&
        selected !== null &&
        records?.length > 0
      ) {
        let fdata = records.filter((e) => e.severityStatus === selected.value);
        setFilter(fdata);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, selected]);

  useEffect(() => {
    let list = filter?.slice(page * 10, page * 10 + 10);
    setTableData(list);
  }, [filter, page]);

  useEffect(() => {
    createColumns();
  }, [column]);

  const dotColumn = {
    name: "",
    cell: (row) => (
      <span>
        {row.severityStatus && (
          <i
            class={`bx bxs-circle me-3 ${
              row.severityStatus === "High Risk"
                ? "text-high"
                : row.severityStatus === "Medium Risk"
                ? "text-medium"
                : row.severityStatus === "Low Risk"
                ? "text-low"
                : row.severityStatus === "Major"
                ? "text-major"
                : row.severityStatus === "Minor"
                ? "text-minor"
                : row.severityStatus === "Good"
                ? "text-good"
                : ""
            }`}
          ></i>
        )}
      </span>
    ),
    width: "50px",
  };

  const createColumns = () => {
    let columnData = column?.map((e, index) => {
      const { fieldName, selector, checked, sortable } = e;
      if (selector === "towerNo") {
        return {
          name: "Tower Number",
          cell: (row) => (
            <span
              className="text-low pointer fw-500"
              onClick={() =>
                navigate("/client-tx-component", {
                  state: {
                    data: location,
                    tower: row,
                    records: records,
                  },
                })
              }
            >
              {row?.towerNo}
            </span>
          ),
        };
      }
      if (selector === "location") {
        return {
          name: "Lat & Long",
          cell: (row) => (
            <span
              className="text-low pointer fw-500"
              onClick={() => handleNavigateToGoogle(row.location)}
            >
              {row?.location}
            </span>
          ),
        };
      }
      if (selector === "towerStatus") {
        return {
          name: "Status",
          cell: (row) => (
            <span
              className={`${
                row?.towerStatus === "Completed"
                  ? "text-complete"
                  : row?.towerStatus === "Inspection Done"
                  ? "text-inspection"
                  : row?.towerStatus === "In Progress"
                  ? "text-inprogress"
                  : "text-saveasdraft"
              }`}
            >
              {row?.towerStatus}
            </span>
          ),
        };
      }
      if (selector === "towerReport") {
        return {
          name: "Tower Report",
          cell: (row) => (
            <span className="text-center">
              <img
                src={towerPdf}
                onClick={row.towerReport ? () => onReportClick(row) : null}
                className={row.towerReport ? "pointer" : ""}
                style={{
                  cursor: row.towerReport ? "pointer" : "not-allowed",
                  opacity: row.towerReport ? 1 : 0.5,
                }}
              />
            </span>
          ),
        };
      }
      if (selector === "uploadJsonData") {
        return {
          name: <span className="font-weight-bold fs-13">Thermal Reports</span>,
          selector: (row) =>
            row.uploadDetails?.[0]?.uploadJsonData?.[0]?.filename ||
            "No Report",
          sortable: false,
          cell: (row) =>
            row.uploadDetails?.[0]?.uploadJsonData?.[0] ? (
              <a
                href={row.uploadDetails[0].uploadJsonData[0].defectFile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary text-decoration-none"
              >
                {row.uploadDetails[0].uploadJsonData[0].filename}
              </a>
            ) : (
              "No Report"
            ),
        };
      }
      if (selector === "inspectionDate") {
        return {
          name: "Inspection Date",
          cell: (row) => <span>{getDateOnly(row.inspectionDate)}</span>,
        };
      }
      return {
        name: <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>,
        selector: (row) => row[selector],
        sortable: false,
      };
    });
    setNewColumn([dotColumn, ...columnData]);
  };

  // const columns = [
  //   {
  //     name: "",
  //     cell: (row) => (
  //       <span>
  //         <i
  //           class={`bx bxs-circle me-3 ${
  //             row.severityStatus === "High Risk"
  //               ? "text-high"
  //               : row.severityStatus === "Medium Risk"
  //               ? "text-medium"
  //               : row.severityStatus === "Low Risk"
  //               ? "text-low"
  //               : row.severityStatus === "Major"
  //               ? "text-major"
  //               : row.severityStatus === "Minor"
  //               ? "text-minor"
  //               : "text-good"
  //           }`}
  //         ></i>
  //       </span>
  //     ),
  //     width: "50px",
  //   },
  //   {
  //     name: "Tower Number",
  //     cell: (row) => (
  //       <span
  //         className="text-low pointer fw-500"
  //         onClick={() =>
  //           navigate("/client-tx-component", {
  //             state: {
  //               data: location,
  //               tower: row,
  //               records: records,
  //             },
  //           })
  //         }
  //       >
  //         {/* {row?.towerNo + " " + row?.towerType} */}
  //         {row?.towerNo}
  //       </span>
  //     ),
  //   },
  //   {
  //     name: "Line Name",
  //     selector: "lineName",
  //     grow: 3,
  //   },
  //   // { name: "Tower Category", selector: "towerCategory" },
  //   { name: "Voltage (kV)", selector: "voltage" },
  //   { name: "Circuit", selector: "circuit" },

  //   { name: "Tower Type", selector: "towerType" },
  //   {
  //     name: "Lat & Long",
  //     cell: (row) => (
  //       <span
  //         className="text-low pointer fw-500"
  //         onClick={() => handleNavigateToGoogle(row.location)}
  //       >
  //         {row?.location}
  //       </span>
  //     ),
  //   },
  //   {
  //     name: "Inspection Date",
  //     cell: (row) => <span>{getDateOnly(row.inspectionDate)}</span>,
  //   },
  //   {
  //     name: "Status",
  //     cell: (row) => (
  //       <span
  //         className={`${
  //           row?.towerStatus === "Completed"
  //             ? "text-complete"
  //             : row?.towerStatus === "Inspection Done"
  //             ? "text-inspection"
  //             : row?.towerStatus === "In Progress"
  //             ? "text-inprogress"
  //             : "text-saveasdraft"
  //         }`}
  //       >
  //         {row?.towerStatus}
  //       </span>
  //     ),
  //   },
  //   // {
  //   //   name: "Screenshot",
  //   //   cell: (row) => {
  //   //     let file = row?.uploadDetails?.filter(
  //   //       (e) => e.fileType === "client_screenshot"
  //   //     );
  //   //     let urls = file?.map((e) =>
  //   //       process.env.REACT_APP_UPLOAD_TYPE === "local"
  //   //         ? process.env.REACT_APP_API_URL +
  //   //           "view/" +
  //   //           e.uploadJsonData?.defectFile
  //   //         : e.uploadJsonData?.defectFile
  //   //     );
  //   //     return (
  //   //       <span className="d-flex align-items-center">
  //   //         <img
  //   //           src={imgScreen}
  //   //           className={`${file.length > 0 ? "pointer" : "auto"} me-2`}
  //   //           onClick={() => {
  //   //             file?.length > 0 ? setIsLightbox(true) : setIsLightbox(false);
  //   //             file?.length > 0
  //   //               ? setLightboxImages(urls)
  //   //               : setLightboxImages([]);
  //   //           }}
  //   //         />
  //   //         <span className="text-low fw-500">
  //   //           {file?.length > 0 ? file?.length + " " + "Images" : "0 Image"}
  //   //         </span>
  //   //       </span>
  //   //     );
  //   //   },
  //   // },
  //   // {
  //   //   name: "Thermal Report",
  //   //   cell: (row) => (
  //   //     <span className="text-center">
  //   //       <img
  //   //         src={reportFile}
  //   //         onClick={() => onThermalReportClick(row)}
  //   //         className="pointer"
  //   //         width={"25%"}
  //   //       />
  //   //     </span>
  //   //   ),
  //   // },
  //   // {
  //   //   name: "Tower Report",
  //   //   cell: (row) => (
  //   //     <span className="text-center">
  //   //       <img
  //   //         src={towerPdf}
  //   //         onClick={() => onReportClick(row)}
  //   //         className="pointer"
  //   //       />
  //   //     </span>
  //   //   ),
  //   // },
  //   {
  //     name: "Tower Report",
  //     cell: (row) => (
  //       <span className="text-center">
  //         <img
  //           src={towerPdf}
  //           onClick={row.towerReport ? () => onReportClick(row) : null}
  //           className={row.towerReport ? "pointer" : ""}
  //           style={{
  //             cursor: row.towerReport ? "pointer" : "not-allowed",
  //             opacity: row.towerReport ? 1 : 0.5,
  //           }}
  //         />
  //       </span>
  //     ),
  //   },
  // ];

  return (
    <>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      {isLightbox && (
        <>
          {lightboxImages?.length > 1 ? (
            <Lightbox
              mainSrc={lightboxImages[idx]}
              imageTitle={lightboxImages[idx]?.split("/")?.pop()}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
              onMovePrevRequest={() =>
                setIdx(
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                )
              }
              onMoveNextRequest={() =>
                setIdx((idx + 1) % lightboxImages?.length)
              }
              nextSrc={lightboxImages[(idx + 1) % lightboxImages?.length]}
              prevSrc={
                lightboxImages[
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                ]
              }
            />
          ) : (
            <Lightbox
              mainSrc={lightboxImages[idx]}
              imageTitle={lightboxImages[idx]?.split("_")?.pop()}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
            />
          )}
        </>
      )}
      <div className="content">
        <Row className="m-3 ">
          <div className=" d-flex justify-content-between">
            <div className="fs-16 fw-500">
              Project Level Tower Health Status
            </div>
            <div className="d-flex align-items-center">
              <img
                src={homeicon}
                onClick={() => navigate("/client-dashboard")}
                className="me-1 pointer pb-1"
              />
              <i class="bx bx-chevron-right fs-4"></i>
              <div
                className="mx-1 fs-14 pointer text-low"
                onClick={() =>
                  navigate("/client-project-dashboard", {
                    state: location.project,
                  })
                }
              >
                {location.project.projectName}
              </div>
              {location?.corridor && (
                <>
                  <i class="bx bx-chevron-right fs-4"></i>
                  <span className="ms-1 color-grayl">
                    {location?.corridor?.corridorName}
                  </span>
                </>
              )}
            </div>
          </div>
        </Row>
        <Row className="mx-3">
          <Card className="br-0" style={{ height: "80px" }}>
            <SearchOption handleSearch={(val) => setSearchTerm(val)} />
            <div className="select-filter">
              <Select
                className="w-3"
                // options={healthOptions}
                options={statusOptions}
                value={selected}
                onChange={(val) => {
                  location.corridor
                    ? setSelected(val)
                    : handleSelectFilter(val);
                }}
                isClearable={true}
              />
            </div>
            {location.corridor && (
              <div
                className="select-corr-report pointer"
                onClick={corridorReport}
              >
                <div className="img-excel me-2">
                  <img src={excel} />
                </div>
                <span className="color-grayl">Corridor Reports</span>
              </div>
            )}
          </Card>
        </Row>
        <Row className="mx-2 tx-tower-client">
          <DataTable data={tableData} columns={newColumn} highlightOnHover />
        </Row>
        <Row className="mx-3 mt-2">
          <Card className="mb-4 p-2 py-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {statusOptions?.length > 0 && (
                  <span className="fw-500 fs-14 me-2">Tower Health Status</span>
                )}
                {statusOptions?.map((ele, idx) => (
                  <React.Fragment key={idx}>
                    <i
                      class={`bx bxs-circle me-1 ${
                        ele?.label === "High Risk" || ele?.label === "Major"
                          ? "text-high"
                          : ele?.label === "Medium Risk" ||
                            ele?.label === "Minor"
                          ? "text-medium"
                          : ele?.label === "Low Risk"
                          ? "text-low"
                          : "text-good"
                      }`}
                    ></i>
                    <span className="me-2 fs-14 color-grayl">{ele?.label}</span>
                  </React.Fragment>
                ))}
              </div>
              <PaginationComponent
                page={page}
                limit={10}
                total={filter?.length}
                onPageChange={(val) => setPage(val)}
              />
            </div>
          </Card>
        </Row>
      </div>
      {isThermal && (
        <Modal isOpen={isThermal} style={{ width: "600px" }}>
          <ModalHeader className="bg-success">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="text-light ">Thermal Report</span>
              <i
                className="bx bx-x fs-3 text-light"
                onClick={() => setIsThermal(false)}
              ></i>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              {thermalRecords?.map((record) => (
                <Col md={12}>
                  <Card className="p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img src={fileimg} className="me-3" />
                        <div>{record?.filename}</div>
                      </div>
                      <div
                        className="text-end"
                        onClick={() => handleDownloadReport(record)}
                      >
                        <i className="bx bx-download fs-3 pointer"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default TxTowers;
