import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../components/commons/functions";
import { useEffect, useState } from "react";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import SearchOption from "../../components/Common/SearchOption";
import { Row } from "react-bootstrap";
import homeicon from "../../assets/svg-new/home-icon.svg";
import { useAppSelector } from "../../store/hooks";
import { corridorColumns } from "../../components/tables/Schema/CorridorSchema";
import Pagination from "../../components/commons/pagination";

const CorridorTable = (props) => {
  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [corridorData, setCorridorData] = useState([]);
  const [sourceData, setSourceData] = useState([]);

  const [column, setColumn] = useState([]);
  const [newColumn, setNewColumn] = useState([]);
  const location = useLocation().state;
  const [page, setPage] = useState({ count: 0, curr: 1 });

  let id = props?.location?.id;

  const getCorridors = () => {
    setLoading(true);

    let url = `getCorridor?projectId=${id}&user=${user?.id}&page=${page.curr}&limit=10`;
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    api.get(url).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setData(res?.response?.rows);
        setPage({ ...page, count: res?.response?.totalCount });
        if (res?.response?.allowedColumns?.length > 0) {
          let filterData = res?.response?.allowedColumns?.filter(
            (e) => e.checked
          );
          setColumn(filterData);
        } else {
          setColumn(corridorColumns);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  useEffect(() => {
    getCorridors();
  }, [searchTerm]);

  useEffect(() => {
    createColumns();
  }, [column]);

  const navigate = useNavigate();

  const createColumns = () => {
    let columnData = column?.map((e) => {
      const { fieldName, selector, checked, sortable } = e;
      if (selector === "user") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
          ),
          selector: (row) =>
            row?.user?.[0]?.firstName + " " + row?.user?.[0]?.lastName,
          sortable: false,
        };
      }
      if (selector === "corridorName") {
        return {
          name: <span className="font-weight-bold fs-13">Corridor Name</span>,
          selector: (row) => (
            <div className="selectable-column" onClick={() => handleClick(row)}>
              {row.corridorName}
            </div>
          ),
          sortable: true,
        };
      }
      return {
        name: <span className="font-weight-bold fs-13">{fieldName}</span>,
        selector: (row) => row[selector],
        sortable: sortable || false,
      };
    });
    setNewColumn(columnData);
  };

  // const columns = [
  //   {
  //     name: <span className="font-weight-bold fs-13">Corridor Name</span>,
  //     selector: (row) => (
  //       <div className="selectable-column" onClick={() => handleClick(row)}>
  //         {row.corridorName}
  //       </div>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Line Name</span>,
  //     sortable: true,
  //     selector: (row) => row.lineName,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Corridor Length</span>,
  //     selector: (row) => row.corridorLength,
  //     sortable: true,
  //     grow: 0.5,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Starting Position</span>,
  //     selector: (row) => {
  //       let lat = row?.startingPosition?.split(",")[0];
  //       let long = row?.startingPosition?.split(",")[1];
  //       return (
  //         <span>
  //           {lat} <span className="fs-18">, </span> {long}
  //         </span>
  //       );
  //     },
  //     grow: 2,
  //     sortable: true,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Ending position</span>,
  //     selector: (row) => {
  //       let lat = row?.endingPosition?.split(",")[0];
  //       let long = row?.endingPosition?.split(",")[1];
  //       return (
  //         <span>
  //           {lat} <span className="fs-18">, </span> {long}
  //         </span>
  //       );
  //     },
  //     grow: 2,
  //     sortable: true,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Number of Towers</span>,
  //     selector: (row) => row.noOfTowers,
  //     sortable: true,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">City/ Division</span>,
  //     selector: (row) => row.address,
  //     sortable: true,
  //   },
  //   {
  //     name: <span className="font-weight-bold fs-13">Status</span>,
  //     selector: (row) => row.corridorStatus,
  //     sortable: true,
  //   },
  // ];

  const handleClick = (row) => {
    const corridor = data.find((e) => e.corridorName === row?.corridorName);
    if (corridor) {
      navigate("/client-tx-towers", {
        state: {
          project: props.location,
          corridor: corridor,
          sources: sourceData,
        },
      });
    }
  };

  const handlePageChange = (val) => {
    setPage({ ...page, curr: val });
  };

  useEffect(() => {
    getCorridors();
  }, [page?.curr]);

  return (
    <>
      {/* <div>
        <SearchOption
          placeholder="Search Corridor Name & City/ Division"
          handleSearch={(val) => setSearchTerm(val)}
        />
        <DataTable columns={newColumn} data={data} />
      </div> */}
      <div className="d-flex align-items-center mb-3">
        <div
          className="me-1"
          onClick={() => {
            props.setCorridorView(false);
            props.setMapView(false);
          }}
        >
          <i
            className="ri-arrow-left-line fs-20"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <div className="flex-grow-1">
          <SearchOption
            placeholder="Search Corridor Name & City/ Division"
            handleSearch={(val) => setSearchTerm(val)}
          />
        </div>
      </div>
      <div>
        <DataTable columns={newColumn} data={data} />
      </div>

      <Pagination
        itemsPerPage={10}
        count={page?.count}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default CorridorTable;
