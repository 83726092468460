import { Calendar } from "primereact/calendar";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import {
  DefectCharts,
  GroupesCharts,
  HealthCharts,
  ObservationCountCharts,
} from "./WidgetsCharts";
import * as api from "../../api/global.api";
import { convertDateToISO } from "../Components/Utils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useAsyncDebounce } from "react-table";

const TxCharts = ({ props, project, showTowerStatus }) => {
  const navigate = useNavigate();
  const [defectData, setDefectData] = useState({});
  const [obsData, setObsData] = useState([]);
  const [obsValue, setObsValue] = useState([]);
  const [healthData, setHealthData] = useState([]);
  const [health, setHealth] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dateVal, setDateVal] = useState([]);
  const fp = useRef(null);
  const [healthSeries, setHelathSeries] = useState([]);
  const [color, setColor] = useState(null);

  const getCharts = async () => {
    // let url = `clientDashboardByProject?projectId=${project?.id}`;
    let url = showTowerStatus
      ? `clientDashboardByProject?projectId=${project?.id}`
      : `clientDashboardByObservations?projectId=${project?.id}`;

    if (selected) {
      url = url + `&componentStatus=${selected?.value}`;
    }
    if (dateVal?.length === 2) {
      const date1 = convertDateToISO(dateVal[0]);
      const date2 = convertDateToISO(dateVal[1]);
      url =
        url +
        `&actionStartDate=${date1}&actionEndDate=${date2}&observationStartDate=${date1}&observationEndDate=${date2}&severityClosedStartDate=${date1}&severityClosedEndDate=${date2}`;
    }
    let res = await api.get(url);

    if (showTowerStatus) {
      let towerActionStatus = res?.response?.towerActionStatus?.filter(
        (e) => e?.severityStatus !== "null" && e?.severityStatus !== "Good"
      );

      let towerStatusDetails = res?.response?.towerStatusDetails;
      delete towerStatusDetails?.null;
      const priorityOrder = [
        "High Risk",
        "Major",
        "Minor",
        "Medium",
        "Low Risk",
        "Good",
      ];

      // Sorting towerActionStatus based on severityStatus order
      towerActionStatus?.sort((a, b) => {
        return (
          priorityOrder.indexOf(a?.severityStatus) -
          priorityOrder.indexOf(b?.severityStatus)
        );
      });

      // Sorting the towerStatusDetails based on the custom order
      const sortedEntries = Object.entries(towerStatusDetails).sort((a, b) => {
        return priorityOrder.indexOf(a[0]) - priorityOrder.indexOf(b[0]);
      });
      const sortedData = Object.fromEntries(sortedEntries);

      let category = towerActionStatus?.map((e) => e?.severityStatus);
      let openvalues = towerActionStatus?.map((e) => e?.Open);
      let closevalues = towerActionStatus?.map((e) => e?.Closed);
      setDefectData({ category, openvalues, closevalues });

      let health = Object?.values(sortedData);
      let health1 = Object.keys(sortedData);
      setHelathSeries(health1);

      let dataColor = health1?.map((val) => {
        val = val?.toLowerCase();
        switch (val) {
          case "high risk":
            return '"#ff0202"';
          case "low risk":
            return '"#086add"';
          case "medium risk":
            // return '"#ffff00"';
            return '"#BDBD16"';
          case "good":
            return '"#3fc304"';
          case "major":
            return '"#bb2626"';
          case "minor":
            return '"#D3D319"';
          default:
            return '"#000000"';
        }
      });
      setHealthData(health || []);
      setColor("[" + dataColor?.join(", ") + "]");

      const componenetStatus = res?.response?.componentStatus || {};
      const dynamicStatusOptions = Object.values(componenetStatus).map(
        (status) => ({
          label: status,
          value: status,
        })
      );
      setStatusOptions(dynamicStatusOptions);
    } else {
      let observationActionStatus = res?.observationActionStatus?.filter(
        (e) => e?.severityStatus !== "null"
      );

      const priorityOrder = [
        "High Risk",
        "Major",
        "Minor",
        "Medium Risk",
        "Low Risk",
        "Good",
      ];

      // Map and sort the data
      const sortedData = observationActionStatus?.sort(
        (a, b) =>
          priorityOrder.indexOf(a.severityStatus) -
          priorityOrder.indexOf(b.severityStatus)
      );

      // Extract data for setting state
      let category = sortedData?.map((e) => e.severityStatus);
      let openvalues = sortedData?.map((e) => e.Open);
      let closevalues = sortedData?.map((e) => e.Closed);
      setDefectData({ category, openvalues, closevalues });

      let dataColor = category?.map((val) => {
        val = val?.toLowerCase();
        switch (val) {
          case "high risk":
            return '"#ff0202"';
          case "low risk":
            return '"#086add"';
          case "medium risk":
            return '"#BDBD16"';
          case "good":
            return '"#3fc304"';
          case "major":
            return '"#bb2626"';
          case "minor":
            return '"#D3D319"';
          default:
            return '"#000000"';
        }
      });

      setColor("[" + dataColor?.join(", ") + "]");
      // Set the state with sorted values and colors
      setHelathSeries(sortedData?.map((e) => e.severityStatus));
      setHealthData(sortedData?.map((e) => e.total));
    }

    let obs = showTowerStatus
      ? res?.response?.observationDetails
      : res?.observation;
    let series = obs?.map((e) => e.observation);
    let value = obs?.map((e) => e.count);
    setObsData(series);
    setObsValue(value);
  };

  const handleChartClick = (idx) => {
    if (idx !== "all") {
      let hels = healthLabels[idx];
      setHealth(hels);
    } else {
      setHealth(idx);
    }
  };

  useEffect(() => {
    if (health) {
      navigate("/client-tx-towers", { state: { project, health: health } });
    }
  }, [health]);

  useEffect(() => {
    getCharts();
  }, [showTowerStatus, selected, dateVal]);

  const colors = ["#FF0202", "#086ADD", "#3FC304", "#EBC505"];

  // console.log(healthData, healthSeries, color);

  return (
    <div className="">
      <Row className="mx-3">
        <Col className="fs-18 fw-500">OverView</Col>
        <Col>
          <div className="d-flex justify-content-end">
            {!showTowerStatus && (
              <Select
                className="me-3 w-30"
                placeholder="Tower Observation Status"
                options={statusOptions}
                onChange={(selectedOption) => setSelected(selectedOption)}
                isClearable={true}
              />
            )}
            <div className={"form-icon flex-nowrap width-300"}>
              <Flatpickr
                className="form-control"
                ref={fp}
                value={dateVal}
                placeholder={"Select by Date Range"}
                options={{
                  mode: "range",
                  dateFormat: "d M, Y",
                  defaultDate: dateVal,
                  maxDate: new Date(),
                }}
                onChange={(e) => {
                  setDateVal(e);
                }}
                isClearable={true}
              />
              {/* <i className="ri-calendar-2-line" /> */}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mx-3">
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title d-flex justify-content-between mb-0">
                {/* <div>Project Level Tower Health Status</div> */}
                <div>
                  {showTowerStatus
                    ? "Project Level Tower Health Status"
                    : "Project level Defect health status"}
                </div>

                <div
                  className="pointer underline text-low"
                  onClick={() => handleChartClick("all")}
                >
                  View All
                </div>
              </h4>
            </CardHeader>

            <CardBody>
              <HealthCharts
                data={healthData}
                healthLabels={healthSeries}
                dataColors={
                  color ? color : '["#FF0202", "#086ADD", "#3FC304", "#EBC505"]'
                }
                selectedIdx={(idx) => {
                  handleChartClick(idx);
                }}
              />
            </CardBody>

            <CardFooter>
              <Row>
                {healthSeries?.map((e, idx) => (
                  <Col key={idx}>
                    <i
                      className="bx bxs-circle"
                      style={{
                        color: color ? JSON?.parse(color)[idx] : colors[idx],
                      }}
                    ></i>{" "}
                    {e} ({healthData[idx]})
                  </Col>
                ))}
              </Row>
            </CardFooter>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                {/* Defect Status At Project Level */}
                <div>
                  {showTowerStatus
                    ? "Tower Status at Project Level"
                    : "Defect Status at Project level"}
                </div>
              </h4>
            </CardHeader>
            <CardBody>
              <GroupesCharts
                dataColors='[ "#CED4DA", "#29EDAB"]'
                data={defectData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {showTowerStatus && (
        <Row className="mt-0">
          <Col className="d-flex justify-content-end me-3">
            <Select
              className="me-3 w-20"
              placeholder="Tower Observation Status"
              options={statusOptions}
              onChange={(selectedOption) => setSelected(selectedOption)}
              isClearable={true}
            />
          </Col>
        </Row>
      )}

      <Row className="mx-3">
        <Col lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title d-flex justify-content-between mb-0">
                Count of Observation at Project Level
              </h4>
            </CardHeader>

            <CardBody>
              <ObservationCountCharts
                dataColors='["#4C9DE3", "#EF7D34", "#3FE3AC", "#D9E352", "#60A0C1", "#9191F5", "#F151FC", "#FCBA51"]'
                data={obsData}
                values={obsValue}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TxCharts;
