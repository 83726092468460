import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import towerPdf from "../../assets/svg-new/pdf-icon.svg";
import { getDateOnly } from "../../Pages/Components/Utils";

const SourceTable = ({
  data,
  handleEdit,
  setOpen,
  handleDelete,
  setMode,
  onActivate,
  columns,
  selectedColumns,
  setSelectedColumns,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const client = useSelector((state) => state?.client?.clientDetails);
  const location = useLocation().state;

  const [tableColumns, setTableColumns] = useState([]);

  const towerActions = {
    name: <span className="font-weight-bold fs-13">Actions</span>,
    sortable: false,

    cell: (row) => {
      return (
        <span>
          <Tooltip target=".custom-target-icon-delete" />
          <Tooltip target=".custom-target-icon-edit" />
          <i
            className="bx bx-edit fs-4 me-2 pointer custom-target-icon-edit"
            style={{ color: "green" }}
            data-pr-tooltip="Edit"
            data-pr-position="top"
            onClick={() => {
              setOpen(true);
              setMode("Edit Tower");
              handleEdit(row);
            }}
          ></i>
          <i
            className="ri-delete-bin-6-line fs-4 me-2 pointer custom-target-icon-delete"
            style={{ color: "red" }}
            data-pr-tooltip="Delete"
            data-pr-position="top"
            onClick={() => {
              handleDelete(row);
            }}
          ></i>
        </span>
      );
    },
  };
  // ];

  useEffect(() => {
    createColumns();
  }, [columns]);

  const onReportClick = (row) => {
    setLoading(true);
    let ele;
    if (
      row.towerReport?.includes("http://") ||
      row.towerReport?.includes("data:") ||
      row.towerReport?.includes("https://")
    ) {
      ele = row.towerReport;
    } else {
      ele = process.env.REACT_APP_API_URL + "view/" + row.towerReport;
    }

    if (ele) {
      const link = document.createElement("a");
      link.href = ele;
      link.target = "_blank";
      link.download = ele.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("Reports not generated yet");
    }
    setLoading(false);
  };

  const createColumns = () => {
    const data = columns?.map((ele, index) => {
      const { fieldName, selector, checked } = ele;

      if (selector === "uploadJsonData") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
          ),
          // <span className="font-weight-bold fs-13">Thermal Reports</span>,
          selector: (row) =>
            row.uploadDetails?.[0]?.uploadJsonData?.[0]?.filename ||
            "No Report",
          sortable: false,
          cell: (row) =>
            row.uploadDetails?.[0]?.uploadJsonData?.[0] ? (
              <a
                href={row.uploadDetails[0].uploadJsonData[0].defectFile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary text-decoration-none"
              >
                {row.uploadDetails[0].uploadJsonData[0].filename}
              </a>
            ) : (
              "No Report"
            ),
        };
      } else if (selector === "towerReport") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
          ),
          cell: (row) => (
            <span className="text-center">
              <img
                src={towerPdf}
                onClick={row.towerReport ? () => onReportClick(row) : null}
                className={row.towerReport ? "pointer" : ""}
                style={{
                  cursor: row.towerReport ? "pointer" : "not-allowed",
                  opacity: row.towerReport ? 1 : 0.5,
                }}
              />
            </span>
          ),
        };
        // return {
        //   name: "Tower Report",
        //   cell: (row) => (
        //     <span className="text-center">
        //       {row.towerReport ? (
        //         <a
        //           href={row.towerReport}
        //           target="_blank"
        //           rel="noopener noreferrer"
        //         >
        //           Tower Report
        //         </a>
        //       ) : (
        //         <span className="text-muted">No Report</span>
        //       )}
        //     </span>
        //   ),
        // };
      } else if (selector === "location") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
            // <span className="font-weight-bold fs-13">Location (lat, long)</span>
          ),
          selector: (row) => {
            let lat = row?.location?.split(",")[0];
            let long = row?.location?.split(",")[1];
            return (
              <span>
                {lat} <span className="fs-18">, </span> {long}
              </span>
            );
          },
          sortable: false,
          grow: 2,
        };
      } else if (selector === "inspectionDate") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
          ),
          cell: (row) => <span>{getDateOnly(row.inspectionDate)}</span>,
        };
      }

      return {
        name: (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => handleCheckboxChange(e, index)}
            />
            <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
          </div>
        ),
        selector: (row) => row[selector],
        sortable: false,
      };
    });

    let finalData = [...data, towerActions];
    setTableColumns(finalData);
  };

  return <DataTable columns={tableColumns} data={data} />;
};

export default SourceTable;
