const towerColumns = [
  {
    fieldName: "Line Name",
    selector: "lineName",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Tower Number",
    selector: "towerNo",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Tower Category",
    selector: "towerCategory",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Tower Type",
    selector: "towerType",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Voltage",
    selector: "voltage",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Structure",
    selector: "structure",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Location",
    selector: "location",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Circuit",
    selector: "circuit",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Phase",
    selector: "phase",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Span",
    selector: "span",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Thermal Reports",
    selector: "uploadJsonData",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Tower Status",
    selector: "towerStatus",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Inspection Date",
    selector: "inspectionDate",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Tower Report",
    selector: "towerReport",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
];

export { towerColumns };
