import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const DefectCharts = ({ dataColors, data }) => {
  var donutchartportfolioColors = getChartColorsArray(dataColors);
  const series = data;
  var options = {
    labels: ["Open", "Closed"],
    chart: {
      type: "donut",
      height: 270,
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "20px",
              color: "#343a40",
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: "13px",
              label: "Total",
              color: "#9599ad",
              fontWeight: 500,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    stroke: {
      lineCap: "round",
      width: 2,
    },
    colors: donutchartportfolioColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="270"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const ObservationCountCharts = ({ dataColors, data, values }) => {
  var barchartCountriesColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: values,
      name: "Observation",
    },
  ];
  var options = {
    chart: {
      type: "bar",
      height: 270,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 0,
      style: {
        fontSize: "18px",
        fontWeight: 400,
        colors: ["#fff"],
      },
    },

    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: data,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="270"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const TowerObservationCountCharts = ({
  dataColors,
  data,
  values,
  selectedData,
}) => {
  var barchartCountriesColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: values,
      name: "Observation",
    },
  ];
  var options = {
    chart: {
      type: "bar",
      height: 270,
      toolbar: {
        show: false,
      },
      events: {
        click: function (chart, w, e) {
          if (e.dataPointIndex !== null) {
            selectedData(e.dataPointIndex);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 0,
      style: {
        fontSize: "18px",
        fontWeight: 400,
        colors: ["#fff"],
      },
    },

    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: data,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="270"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const HealthCharts = ({ dataColors, data, healthLabels, selectedIdx }) => {
  var donutchartportfolioColors = getChartColorsArray(dataColors);
  var options = {
    labels: healthLabels,
    chart: {
      type: "donut",
      height: 270,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (config.dataPointIndex !== null) {
            selectedIdx ? selectedIdx(config.dataPointIndex) : "null";
          }
        },
      },
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "90px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "20px",
              color: "#343a40",
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: "13px",
              label: "Total",
              color: "#9599ad",
              fontWeight: 500,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    stroke: {
      lineCap: "round",
      width: 2,
    },
    colors: donutchartportfolioColors,
    // tooltip: {
    //   enabled: true,
    //   theme: "dark",
    //   style: {
    //     fontSize: "14px",
    //     fontFamily: "Arial, sans-serif",
    //   },
    //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //     const value = series[seriesIndex];
    //     const seriesName = w.globals.seriesNames[seriesIndex];
    //     let tooltipColor;
    //     if ( seriesName === "High") {
    //       // if (seriesName === "Medium Risk" || seriesName === "Minor" || seriesName === "High Risk" || seriesName === "Major" || seriesName === "Low Risk" || seriesName === "Good") {
    //       tooltipColor = "#000000";
    //     } else {
    //       tooltipColor = "#ffffff";
    //     }

    //     // Get the background color of the current donut slice
    //     const backgroundColor = w.config.colors[seriesIndex];

    //     // Return the custom tooltip content and styling
    //     return (
    //       '<div style="padding: 10px; background-color:' +
    //       backgroundColor +
    //       "; color:" +
    //       tooltipColor +
    //       ' !important; border-radius: 5px; font-size: 14px; font-family: Arial, sans-serif; text-align: center;">' +
    //       "<strong style='color:" +
    //       tooltipColor +
    //       " !important;'>" +
    //       seriesName +
    //       ":</strong> " +
    //       value +
    //       "</div>"
    //     );
    //   },
    // },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={data}
        type="donut"
        height="270"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const GroupesCharts = ({ dataColors, data }) => {
  var chartGroupbarColors = getChartColorsArray(dataColors);
  const series = [
    { name: "Open", data: data?.openvalues || [] },
    {
      name: "Closed",
      data: data?.closevalues || [],
    },
  ];

  var options = {
    chart: {
      type: "bar",
      height: 290,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: data?.category || [],
    },
    colors: chartGroupbarColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bar"
        height={305}
      />
    </React.Fragment>
  );
};
export {
  DefectCharts,
  ObservationCountCharts,
  HealthCharts,
  TowerObservationCountCharts,
  GroupesCharts,
};
