const corridorColumns = [
  {
    fieldName: "Corridor",
    selector: "corridorName",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Line Name",
    selector: "lineName",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Corridor Length",
    selector: "corridorLength",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Starting Position",
    selector: "startingPosition",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Ending Position",
    selector: "endingPosition",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "No of Towers",
    selector: "noOfTowers",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  // {
  //   fieldName: "SME",
  //   selector: "sme",
  //   checked: true,
  //   width: "50px",
  //   sortable: false,
  //   grow: 1,
  // },
  {
    fieldName: "Client User",
    selector: "user",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "City",
    selector: "address",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "State",
    selector: "state",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Created Date",
    selector: "createdAt",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
  {
    fieldName: "Status",
    selector: "corridorStatus",
    checked: true,
    width: "50px",
    sortable: false,
    grow: 1,
  },
];

export { corridorColumns };
