import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ActionButtons from "../../actionsComponent/ActionButtons";
import Breadcrum from "../../commons/Breadcrum";
import {
  dataTypesArr,
  errorMsg,
  headersArr,
  mapSources,
  successMsg,
} from "../../commons/constant";
import PrimaryTable from "../../primaryTable/PrimaryTable";
import { Projects } from "../ProjectManagementSchema";
import { Accordion, AccordionTab } from "primereact/accordion";
import * as globalApi from "../../../api/global.api";
//import { Accordion, AccordionItem, Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap';
import "../Projects.css";
import ProjectDetailsModal from "./ProjectDetailsModal";
import Loader from "../../CommonComponent/Loader";
import { addToast, getMode, GetMonths, sortBy } from "../../commons/common";
import { toast, ToastContainer } from "react-toastify";
import UnAuthDialog from "../../commons/UnAuthDialog";
import { FileUploadDemo } from "../../commons/FileUpload";
import KmlViewModal from "./KmlViewModal";
import AddNew from "./AddNew";
import DeleteAssignPilotModal from "./DeleteAssignPilotModal";
import { useAppSelector } from "../../../store/hooks";
import "../../../index.css";
import DataTable from "react-data-table-component";
import { Col, Row } from "reactstrap";
import DeleteModal from "../../Common/DeleteModal";
import Add3DSource from "./Add3DSource";

const AddProjectDetails = () => {
  const projectIdentifier: any = useLocation().state;
  const navigate = useNavigate();
  let formType = getMode(window.location.hash);
  let mode = formType.mode;
  let id = formType.id;
  let project = useAppSelector((state) => state.project);
  let survey = useAppSelector((state) => state.survey);
  const [spinner, setSpinner] = useState<any>(false);
  const [header, setHeader] = useState("");
  const steps = [
    { ind: 1, label: "Layers" },
    { ind: 2, label: "Attributes" },
  ];
  const [showAddBase, setShowAddBase] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const maps = mapSources;
  const [data, setData] = useState<any>();
  const [headers, setheaders] = useState<any>(headersArr);
  const [type, setType] = useState("layers");
  const [layerColumns, setLayerColumns] = useState<any>();
  const [activeIndex, setActiveIndex] = useState<any>("");
  //   const [options, setOptions] = useState<any>([]);
  const [kind, setKind] = useState("");
  const [baseRows, setBaseRows] = useState<any>([]);
  const [sourceRows, setSourceRows] = useState<any>([]);
  const [pointRows, setPointRows] = useState<any>([]);
  const [reportRows, setReportRows] = useState<any>([]);
  const [layerRows, setLayerRows] = useState<any>([]);
  const [propertyRows, setPropertyRows] = useState<any>([]);
  const [lineRows, setLineRows] = useState<any>([]);
  const [downloadRows, setDownloadRows] = useState<any>([]);
  const [polygonRows, setPolygonRows] = useState<any>([]);
  const [markerRows, setMarkerRows] = useState<any>([]);
  const [symbolRows, setSymbolRows] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [skip, setSkip] = useState<number>(0);
  const [pageRows, setPageRows] = useState(10);
  const [searchFieldKey, setSearchFieldKey] = useState<any>({});
  const [totalRecords, setTotalRecords] = useState<number>();
  const [first, setFirst] = useState<number>(0);
  const [surveys, setSurveys] = useState<any>([]);
  const [baseCount, setBaseCount] = useState(0);
  const [sourceCount, setSourceCount] = useState(0);
  const [layerCount, setLayerCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [pointCount, setPointCount] = useState(0);
  const [lineCount, setLineCount] = useState(0);
  const [polygonCount, setPolygonCount] = useState(0);
  const [symbolCount, setSymbolCount] = useState(0);
  const [showLogout, setShowLogout] = useState(false);
  const [formState, setFormState] = useState<any>({});
  const [showUpload, setShowUpload] = useState(true);
  const [pilots, setPilots] = useState<any>([]);
  const [disableSave, setDisableSave] = useState(true);
  const [kmlView, setKmlView] = useState(false);
  const [kmlRows, setKmlRows] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [vectors, setVectors] = useState<any>([]);
  const [rasters, setRasters] = useState<any>([]);
  const [allSources, setAllSources] = useState<any>([]);
  const [layers, setLayers] = useState<any>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [count, setCount] = useState(0);
  const [allPoints, setAllPoints] = useState<any>([]);
  const [allLines, setAllLines] = useState<any>([]);
  const [allPolygons, setAllPolygons] = useState<any>([]);
  const [allSymbols, setAllSymbols] = useState<any>([]);
  const [enableSourceAttribute, setEnableSourceAttribute] = useState(false);

  const [source3dData, setSource3dData] = useState<any>([]);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>(null);
  const [add3dModal, setAdd3dModal] = useState<boolean>(false);

  useEffect(() => {
    getSourcesAndLayers();
    // getAttributes();
    getSurveyById();
    get3DSources();
  }, []);
  useEffect(() => {
    setSpinner(true);
    if (pageIndex !== 4) getRows("page=0&limit=10", false, "", false);
    else getRows("page=0&limit=10", true, "Marker", false);
  }, [pageIndex]);
  useEffect(() => {
    if (vectors?.length > 0 && layers?.length > 0) {
      vectors?.map((obj: any) => {
        let ans = layers?.find((ele: any) => {
          if (obj?.sourceid === ele?.sourceid) return ele;
          else return undefined;
        });
        if (ans === undefined) {
          setCount((prev) => prev + 1);
        }
      });
    }
  }, [layers, vectors]);

  const getSurveyById = () => {
    let url = "survey/getbyId/" + survey.id;
    globalApi.get(url).then((resp) => {
      if (resp?.status?.toLowerCase() === "failed") {
        addToast(errorMsg.SURVEY_GET, "error");
      } else if (resp === 401) {
        setShowLogout(true);
      } else {
        let arr = resp?.response?.deliverables?.filter(
          (obj: any) => obj?.deliverableName === "Property Image"
        );

        arr.length > 0
          ? setEnableSourceAttribute(true)
          : setEnableSourceAttribute(false);
      }
    });
  };

  const getSourcesAndLayers = () => {
    globalApi.get("sources/get/" + id + "?page=0&limit=100000").then((res) => {
      if (res?.rows) {
        setAllSources(res?.response?.rows);
        let vectorSources = res?.response?.rows?.filter(
          (row: any) => row?.dataType === "vector"
        );
        let rasterSources = res?.response?.rows?.filter(
          (row: any) => row?.dataType === "raster"
        );

        setCount(0);
        setRasters([...rasterSources]);
        setVectors([...vectorSources]);
      }
    });

    // globalApi
    //   .get("vectorlayer/get/" + id + "?page=0&limit=100000")
    //   .then((res) => {
    //     setCount(0);
    //     setLayers(res.rows);
    //   });
  };

  // const getAttributes = () => {
  //   globalApi.get("point/get/" + id + "?page=0&limit=100000").then((res) => {
  //     setAllPoints(res?.response?.rows);
  //   });
  //   globalApi
  //     .get("linestring/get/" + id + "?page=0&limit=100000")
  //     .then((res) => {
  //       setAllLines(res?.response?.rows);
  //     });
  //   globalApi.get("polygon/get/" + id + "?page=0&limit=100000").then((res) => {
  //     setAllPolygons(res?.response?.rows);
  //   });
  //   globalApi.get("symbol/get/" + id + "?page=0&limit=100000").then((res) => {
  //     setAllSymbols(res?.response?.rows);
  //   });
  // };

  const getRows = (
    queryParams: string,
    set: boolean,
    type: string,
    paginator: boolean
  ) => {
    setSpinner(true);
    if (pageIndex === 0) {
      // globalApi
      //     .get("basemap/get/" + id + "?" + queryParams)
      //     .then((resp) => {
      //       if (resp?.status?.toLowerCase() === "failed") {
      //         addToast(errorMsg.BASEMAP_GET, "error");
      //       } else if (data === 401) {
      //         setShowLogout(true);
      //       } else {
      //         if (resp.rows) {
      //           resp.rows.map((res: any) => {
      //             res.selectMap = res.mapname;
      //             res.mapSource = maps.find(
      //               (source: any) => source.value === res.style
      //             )?.mapSource;
      //             res.style = res.style;
      //             res.defaultMap = res.isActive;
      //             res.type = "Base";
      //             res.id = res.mapid;
      //           });
      //           setBaseCount(resp.totalCount);
      //           setBaseRows(resp.rows);
      //           if (queryParams === "page=0&limit=10") {
      //             setFirst(0);
      //             setPageRows(10);
      //           }
      //         }
      //       }
      //       setSpinner(false);
      //     })
      //     .catch((err) => {
      //       addToast(errorMsg.BASEMAP_GET, "error");
      //       setSpinner(false);
      //     });
      // } else if (pageIndex === 1) {
      globalApi
        .get("sources/get/" + id + "?" + queryParams)
        .then((resp) => {
          if (resp?.status?.toLowerCase() === "failed") {
            addToast(errorMsg.SOURCES_GET, "error");
          } else if (data === 401) {
            setShowLogout(true);
          } else {
            if (resp.response.rows) {
              resp.response.rows.map((res: any) => {
                res.type = "Source";
              });
              if (!paginator) {
                setSourceRows(resp.response.rows);
                setSourceCount(resp.response.totalCount);
              }
              if (set && activeIndex === 0) {
                setRows(resp.response.rows);
                setSourceRows(resp.response.rows);
                setSourceCount(resp.response.totalCount);
              }
              if (queryParams === "page=0&limit=10") {
                setFirst(0);
                setPageRows(10);
              }
            }
          }
          setSpinner(false);
        })
        .catch((err) => {
          addToast(errorMsg.SOURCES_GET, "error");
          setSpinner(false);
        });

      // globalApi
      //   .get("vectorlayer/get/" + id + "?" + queryParams)
      //   .then((resp) => {
      //     if (resp?.status?.toLowerCase() === "failed") {
      //       addToast(errorMsg.LAYER_GET, "error");
      //     } else if (data === 401) {
      //       setShowLogout(true);
      //     } else {
      //       if (resp.rows) {
      //         resp.rows.map((res: any) => {
      //           res.visibility = res.visibility === 0 ? false : true;
      //           res.type = "Layers";
      //           res.id = res.layerid;
      //         });
      //         if (set && activeIndex === 1) {
      //           setRows(resp.rows);
      //           setLayerRows(resp.rows);
      //           setLayerCount(resp.totalCount);
      //         }
      //         if (!paginator) {
      //           setLayerRows(resp.rows);
      //           setLayerCount(resp.totalCount);
      //         }
      //         if (queryParams === "page=0&limit=10") {
      //           setFirst(0);
      //           setPageRows(10);
      //         }
      //       }
      //     }
      //     setSpinner(false);
      //   })
      //   .catch((err) => {
      //     addToast(errorMsg.LAYER_GET, "error");
      //     setSpinner(false);
      //   });

      globalApi
        .get("sourceattribute/get/" + id + "?" + queryParams)
        .then((resp) => {
          if (resp?.status?.toLowerCase() === "failed") {
            addToast(errorMsg.PROPERTIES_GET, "error");
          } else if (data === 401) {
            setShowLogout(true);
          } else {
            if (resp.response.rows) {
              if (set && activeIndex === 2) {
                setRows(resp.response.rows);
                setPropertyRows(resp.response.rows);
                setPropertiesCount(resp.response.totalCount);
              }
              if (!paginator) {
                setPropertyRows(resp.response.rows);
                setPropertiesCount(resp.response.totalCount);
              }
              if (queryParams === "page=0&limit=10") {
                setFirst(0);
                setPageRows(10);
              }
            }
          }
          setSpinner(false);
        })
        .catch((err) => {
          addToast(errorMsg.PROPERTIES_GET, "error");
          setSpinner(false);
        });
    }
    // else if (pageIndex === 1) {
    //   globalApi
    //     .get("point/get/" + id + "?" + queryParams)
    //     .then((resp) => {
    //       if (resp?.status?.toLowerCase() === "failed") {
    //         addToast(errorMsg.POINT_GET, "error");
    //       } else if (data === 401) {
    //         setShowLogout(true);
    //       } else {
    //         if (resp.rows) {
    //           resp.rows.map((res: any) => {
    //             res.text = res.textfield;
    //             res.type = "Point";
    //             res.id = res.pointlayerid;
    //           });
    //           if (set && type === "Point") {
    //             setRows(resp.rows);
    //             setPointRows(resp.rows);
    //             setPointCount(resp.totalCount);
    //           }
    //           if (!paginator) {
    //             setPointRows(resp.rows);
    //             setPointCount(resp.totalCount);
    //           }
    //           if (queryParams === "page=0&limit=10") {
    //             setFirst(0);
    //             setPageRows(10);
    //           }
    //         }
    //       }
    //       setSpinner(false);
    //     })
    //     .catch((err) => {
    //       addToast(errorMsg.POINT_GET, "error");
    //       setSpinner(false);
    //     });
    //   globalApi
    //     .get("linestring/get/" + id + "?" + queryParams)
    //     .then((resp) => {
    //       if (resp?.status?.toLowerCase() === "failed") {
    //         addToast(errorMsg.LINE_GET, "error");
    //       } else if (data === 401) {
    //         setShowLogout(true);
    //       } else {
    //         resp.rows.map((res: any) => {
    //           res.type = "Line";
    //           res.id = res.linestringlayerid;
    //           res.attribute = res.attribute ? res.attribute : "-";
    //         });
    //         if (set && type === "Line") {
    //           setRows(resp.rows);
    //           setLineRows(resp.rows);
    //           setLineCount(resp.totalCount);
    //         }
    //         if (!paginator) {
    //           setLineRows(resp.rows);
    //           setLineCount(resp.totalCount);
    //         }
    //         if (queryParams === "page=0&limit=10") {
    //           setFirst(0);
    //           setPageRows(10);
    //         }
    //       }
    //       setSpinner(false);
    //     })
    //     .catch((err) => {
    //       addToast(errorMsg.LINE_GET, "error");
    //       setSpinner(false);
    //     });
    //   globalApi
    //     .get("polygon/get/" + id + "?" + queryParams)
    //     .then((resp) => {
    //       if (resp?.status?.toLowerCase() === "failed") {
    //         addToast(errorMsg.POLYGON_GET, "error");
    //       } else if (data === 401) {
    //         setShowLogout(true);
    //       } else {
    //         resp.rows.map((res: any) => {
    //           res.text = res.textfield;
    //           res.type = "Polygon";
    //           res.id = res.polygonid;
    //         });
    //         if (set && type === "Polgon") {
    //           setRows(resp.rows);
    //           setPolygonRows(resp.rows);
    //           setPolygonCount(resp.totalCount);
    //         }
    //         if (!paginator) {
    //           setPolygonRows(resp.rows);
    //           setPolygonCount(resp.totalCount);
    //         }
    //         if (queryParams === "page=0&limit=10") {
    //           setFirst(0);
    //           setPageRows(10);
    //         }
    //       }
    //       setSpinner(false);
    //     })
    //     .catch((err) => {
    //       addToast(errorMsg.POLYGON_GET, "error");
    //       setSpinner(false);
    //     });
    //   globalApi
    //     .get("symbol/get/" + id + "?" + queryParams)
    //     .then((resp) => {
    //       if (resp?.status?.toLowerCase() === "failed") {
    //         addToast(errorMsg.SYMBOL_GET, "error");
    //       } else if (data === 401) {
    //         setShowLogout(true);
    //       } else {
    //         if (set && type === "Symbol") {
    //           setRows(resp.rows);
    //           setSymbolRows(resp.rows);
    //           setSymbolCount(resp.totalCount);
    //         }
    //         if (!paginator) {
    //           setSymbolRows(resp.rows);
    //           setSymbolCount(resp.totalCount);
    //         }
    //         if (queryParams === "page=0&limit=10") {
    //           setFirst(0);
    //           setPageRows(10);
    //         }
    //       }
    //       setSpinner(false);
    //     })
    //     .catch((err) => {
    //       addToast(errorMsg.POLYGON_GET, "error");
    //       setSpinner(false);
    //     });
    // }
  };
  const handleMap = (rowData: any) => {
    setSpinner(true);
    let url = "";
    let obj = {};
    let invalid = false;
    if (type === "Layers") {
      url = "vectorlayer/update";
      obj = {
        layerid: parseInt(rowData.layerid),
        visibility: rowData.visibility ? 0 : 1,
      };
    } else if (type === "Properties") {
      url = "sourceattribute/update";
      obj = {
        sourceAttributeID: rowData.sourceAttributeID,
        visibility: !rowData.visibility,
      };
    }
    if (!invalid)
      globalApi
        .post(url, obj)
        .then((resp) => {
          if (resp?.status?.toLowerCase() === "failed") {
            addToast(
              type === "BaseMap"
                ? errorMsg.BASEMAP_EDIT
                : type === "Layers"
                ? errorMsg.LAYER_EDIT
                : type === "Properties"
                ? errorMsg.PROPERTIES_DELETE
                : "",
              "error"
            );
            setSpinner(false);
          } else if (data === 401) {
            setShowLogout(true);
          } else {
            getRows("page=0&limit=10", true, type, false);
          }
        })
        .catch((err) => {
          addToast(
            type === "Layers"
              ? errorMsg.LAYER_EDIT
              : type === "Properties"
              ? errorMsg.PROPERTIES_DELETE
              : "",
            "error"
          );
          setSpinner(false);
        });
    else setSpinner(false);
  };
  const getRowsAndOptions = (key: string) => {
    if (key === "Sources") {
      setRows(sourceRows);
      setTotalRecords(sourceCount);
      //   setOptions([{ id: "1", value: "Source 1" }]);
    } else if (key === "Point") {
      setTotalRecords(pointCount);
      setRows(pointRows);
      //   setOptions([{ id: "1", value: "Point 1" }]);
    } else if (key === "Layers") {
      setRows(layerRows);
      setTotalRecords(layerCount);
      //   setOptions([{ id: "1", value: "Layers 1" }]);
    } else if (key === "Properties") {
      setTotalRecords(propertiesCount);
      setRows(propertyRows);
    } else if (key === "Line") {
      setTotalRecords(lineCount);
      setRows(lineRows);
      //   setOptions([{ id: "1", value: "Line 1" }]);
    } else if (key === "Polgon") {
      setTotalRecords(polygonCount);
      setRows(polygonRows);
      //   setOptions([{ id: "1", value: "Polygon 1" }]);
    } else if (key === "Symbol") {
      setTotalRecords(symbolCount);
      setRows(symbolRows);
    }
  };
  const handleAdd = (type: string) => {
    setData({});
    setHeader(type);
    setKind("Add");
    setShowAddBase(true);
  };
  const handleAddService = () => {
    setSpinner(true);
    let url = "";
    let obj: any = {};
    let service = kind.toLowerCase() === "add" ? "add" : "update";
    let successMessage = "";
    let errorMessage = "";
    switch (type) {
      case "Sources":
        successMessage =
          kind.toLowerCase() === "add"
            ? successMsg.SOURCE_ADD
            : successMsg.SOURCE_EDIT;
        errorMessage =
          kind.toLowerCase() === "add"
            ? errorMsg.SOURCE_ADD
            : errorMsg.SOURCE_EDIT;
        url = `sources/${service}`;
        obj = {
          dataType: data.datatype,
          layerType: data?.layerType,
          epsg: data?.epsg,
          sourceName: data.source_name,
          sourceLink: data.source_link,
          priority: parseInt(data.priority),
          sourceDisplayName: data.sourceDisplayName,
          visibility: Number(data.visibility),
          clientId: project?.clientId,
          survey: { connect: { id: parseInt(id) } },
          project: { connect: { id: parseInt(projectIdentifier) } },
          geoServerStorage: data?.geoServerStorage + " " + data?.fileSizeType
        };
        if (kind === "Edit") {
          delete obj.survey;
          delete obj.projectId;
          delete obj.project;
          obj.projectId = parseInt(projectIdentifier);
          obj.surveyId = parseInt(id);
          obj.id = parseInt(data.id);
        }
        break;
    }

    globalApi
      .post(url, obj)
      .then((data) => {
        if (data) {
          if (data?.status?.toLowerCase() === "failed") {
            addToast(errorMessage, "error");
            setSpinner(false);
          } else if (data === 401) {
            setShowLogout(true);
          } else {
            addToast(successMessage, "success");
            getSourcesAndLayers();
            // getAttributes();
            getRows("page=0&limit=10", true, type, false);
            setShowAddBase(false);
          }
        }
      })
      .catch((err) => {
        addToast(errorMessage, "error");
        setData({});
        setSpinner(false);
      });
  };
  const handleSave = () => {
    handleAddService();
    setHeader("");
    setShowAddBase(false);
  };
  const handleEdit = (rowData: any, key: string) => {
    setKind("Edit");
    const arr = rowData?.geoServerStorage?.split(" ")
    if (key === "Source") {
      let data1 = {
        visibility: rowData?.visibility === 1 ? true : false,
        datatype: rowData.dataType,
        sourceDisplayName: rowData.sourceDisplayName,
        source_link: rowData.sourceLink,
        source_name: rowData.sourceName,
        id: rowData.id,
        priority: rowData.priority,
        survey_id: rowData.surveyId,
        epsg: rowData?.epsg,
        layerType: rowData?.layerType,
        geoServerStorage: arr?.[0],
        fileSizeType: arr?.[1]
      };
      setData(data1);
    }
    setHeader(headers[key]);
    setShowAddBase(true);
  };

  const handlePrevious = () => {
    setData({});
    setActiveIndex("");
    if (pageIndex - 1 === 0) {
      setType("BaseMap");
      getRowsAndOptions("BaseMap");
    } else if (pageIndex - 1 === 2) {
      setType("Attribute");
      getRowsAndOptions("Attribute");
    }
    // setValue("");
    setPageIndex(pageIndex - 1);
  };
  const handleNext = () => {
    if (pageIndex === 2) {
      navigate("/delivers/surveys/" + project.projectid);
      return;
    }
    setData({});
    setActiveIndex("");
    if (pageIndex === 1 && allSources?.length === rasters?.length) {
      navigate("/delivers/surveys/" + project.projectid);
      return;
    } else if (pageIndex + 1 === 2) {
      setType("Attribute");
      getRowsAndOptions("Attribute");
    }
    setPageIndex(pageIndex + 1);
  };
  const onStepClick = (index: number) => {
    setData({});
    setActiveIndex("");
    if (index === 0) {
      setType("BaseMap");
      getRowsAndOptions("BaseMap");
    } else if (index === 2) {
      setType("Attributes");
      getRowsAndOptions("Attributes");
    }
    // setDisableStep(index);
    // setValue("");
    setPageIndex(index);
  };
  const handleTabChange = (e: any, key: string) => {
    setData({});

    if (e.index === 0) {
      setType(
        key === "layers"
          ? "Sources"
          : key === "attributes"
          ? "Point"
          : key === "deliverables"
          ? "Reports"
          : ""
      );
      getRowsAndOptions(
        key === "layers"
          ? "Sources"
          : key === "attributes"
          ? "Point"
          : key === "deliverables"
          ? "Reports"
          : ""
      );
      setLayerColumns(
        key === "layers"
          ? Projects.Sources
          : key === "attributes"
          ? Projects.Point
          : key === "deliverables"
          ? Projects.Reports
          : ""
      );
      setFirst(0);
      setPageRows(10);
    } else if (e.index === 1) {
      setType(
        key === "layers"
          ? "Layers"
          : key === "attributes"
          ? "Line"
          : key === "deliverables"
          ? "Downloads"
          : ""
      );
      getRowsAndOptions(
        key === "layers"
          ? "Layers"
          : key === "attributes"
          ? "Line"
          : key === "deliverables"
          ? "Downloads"
          : ""
      );
      setLayerColumns(
        key === "layers"
          ? Projects.Layers
          : key === "attributes"
          ? Projects.Line
          : key === "deliverables"
          ? Projects.Downloads
          : ""
      );
      setFirst(0);
      setPageRows(10);
    } else if (e.index === 2) {
      setType(
        key === "layers" ? "Properties" : key === "attributes" ? "Polgon" : ""
      );
      getRowsAndOptions(
        key === "layers" ? "Properties" : key === "attributes" ? "Polgon" : ""
      );
      setLayerColumns(
        key === "layers"
          ? Projects.Properties
          : key === "attributes"
          ? Projects.Polygon
          : ""
      );
      setFirst(0);
      setPageRows(10);
    } else if (e.index === 3) {
      setType(key === "attributes" ? "Symbol" : "");
      getRowsAndOptions(key === "attributes" ? "Symbol" : "");
      setLayerColumns(key === "attributes" ? Projects.Symbol : "");
      setFirst(0);
      setPageRows(10);
    }
    // setValue("");
    setActiveIndex(e.index);
  };

  const layersComponent = (key: string) => {
    let type = "Add " + key;
    if (key === "Properties") type = "Add Source Attribute";
    return (
      <>
        <div className="text-right mt-3 mb-3">
          <Button onClick={() => handleAdd(type)} className="btn-primary">
            <i className="pi pi-plus-circle m-r-10" />
            {type}
          </Button>
        </div>
        <PrimaryTable
          columns={key === "Marker" ? Projects.Markers : layerColumns}
          rows={rows}
          onClickSwitch={handleMap}
          handleRowEdit={(rowData: any) => handleEdit(rowData, key)}
          paginator={rows.length > 0 ? true : false}
          emptyMessage={
            key === "Properties"
              ? "No Source Attributes Available"
              : "No " + key + "s Available"
          }
          onClickDelete={handleDelete}
          lazyLoad
          onPageChange={(e: any) => onPageChange(e)}
          searchRecord={searchRecord}
          pageRows={pageRows}
          count={skip}
          filterKeys={searchFieldKey}
          totalRecords={totalRecords}
          first={first}
          scrollHeight="400px"
        />
      </>
    );
  };
  const handleFormChange = (e: any, key: any, type: any, error: any) => {
    if (key === "selectMap") {
      if (typeof e.trim === "function" && e.trim(" ") === "") {
        e = e.trim(" ");
      }
    }
    if (key === "pdf_link") {
      errors[key] = error[key];
      setErrors(errors);
    }
    if (kind === "Edit") {
      setData({ ...data, [key]: e });
    } else {
      setData({ ...data, [key]: e, visibility: true });
    }
  };
  const searchRecord = (e: any, column: any, filterKeys: any) => {
    let keyData = { ...filterKeys };
    setSearchFieldKey(keyData);
    let queryPrams = Object.keys(filterKeys)
      .map((filterKey: any) => {
        if (filterKeys[filterKey]) {
          return filterKey + "=" + filterKeys[filterKey];
        }
      })
      .join("&");
    setSpinner(true);
    getRows(queryPrams, true, "", false);
  };
  const onPageChange = (event: any) => {
    let pageUrl = `page=${event.page}&limit=${event.rows}`;
    setSpinner(true);
    getRows(pageUrl, true, type, true);
    setPageRows(event.rows);
    setFirst(event.first);
    setSkip(event.skip);
  };
  const handleDelete = (rowData: any) => {
    setSpinner(true);
    let url = "";
    let successMessage = "";
    let errorMessage = "";
    switch (type) {
      case "BaseMap":
        successMessage = successMsg.BASEMAP_DELETE;
        errorMessage = errorMsg.BASEMAP_DELETE + rowData.mapid;
        url = "basemap/delete/" + rowData.mapid;
        break;
      case "Sources":
        successMessage = successMsg.SOURCE_DELETE;
        errorMessage = errorMsg.SOURCE_DELETE + rowData.sourceid;
        url = "sources/delete/" + rowData.id;
        break;
      case "Layers":
        successMessage = successMsg.LAYER_DELETE;
        errorMessage = errorMsg.LAYER_DELETE + rowData.layerid;
        url = "vectorlayer/delete/" + rowData.layerid;
        break;
      case "Properties":
        successMessage = successMsg.PROPERTIES_DELETE;
        errorMessage = errorMsg.PROPERTIES_DELETE;
        url = "sourceattribute/delete/" + rowData.sourceAttributeID;
        break;
      case "Point":
        successMessage = successMsg.POINT_DELETE;
        errorMessage = errorMsg.POINT_DELETE + rowData.pointlayerid;
        url = `point/delete/${rowData.pointlayerid}`;
        break;
      case "Line":
        successMessage = successMsg.LINE_DELETE;
        errorMessage = errorMsg.LINE_DELETE + rowData.linestringlayerid;
        url = `linestring/delete/${rowData.linestringlayerid}`;
        break;
      case "Polgon":
        successMessage = successMsg.POLYGON_DELETE;
        errorMessage = errorMsg.POLYGON_DELETE + rowData.polygonid;
        url = `polygon/delete/${rowData.polygonid}`;
        break;
      case "Symbol":
        successMessage = successMsg.SYMBOL_DELETE;
        errorMessage = errorMsg.SYMBOL_DELETE + rowData.symbolid;
        url = `symbol/delete/${rowData.symbolid}`;
        break;
    }
    globalApi
      .get(url)
      .then((resp) => {
        if (resp?.status?.toLowerCase() === "failed") {
          addToast(errorMessage, "error");
        } else if (data === 401) {
          setShowLogout(true);
        } else {
          addToast(successMessage, "success");
          getRows("page=0&limit=10", true, type, false);
          getSourcesAndLayers();
          // getAttributes();
        }
        setSpinner(false);
      })
      .catch((err) => {
        addToast(errorMessage, "error");
        setSpinner(false);
      });
  };
  const onFileUpload = (file: any) => {
    setSpinner(true);
    let name = "";
    if (file.name && formState.firstName) {
      let type = file.name?.split(".").pop();
      name = formState.firstName + "." + type;
    }
    let data = new FormData();
    data.append("file", file);
    data.append("firstName", name);
    data.append("surveyid", id);
    let headers = { "content-type": "multipart/form-data" };
    let url = "files/xlUpload";
    globalApi.imgUpload(url, data, headers).then((resp) => {
      setDisableSave(false);
      setSpinner(false);
    });
  };
  const getPilotAssignments = (surveyid: any) => {
    globalApi.get("pilot/get?surveyid=" + id).then((resp) => {
      if (resp.length > 0) {
        setShowUpload(false);
      }
      resp.map((res: any) => {
        if (res.uploadFile) res.kmlfile = "KML Files";
        res.assignPilot = pilots.find(
          (pilot: any) => pilot.userId === res.userId
        );
      });
      sortBy(resp, "division");
      setRows(resp);
      setSpinner(false);
    });
  };
  const handleUploadSave = () => {
    setShowUpload(false);
    setSpinner(true);
    getPilotAssignments("");
  };
  const handleDropDownChange = (e: any, id: any, type: string) => {
    if (type === "assignPilot") {
      setSpinner(true);
      let dataRows = [...rows];
      let row = dataRows.find((obj) => obj.assignmentId === id);
      row.assignPilot = e.target.value.name_role;
      row.userId = e.target.value.userId;
      delete row.kmlfile;
      delete row.projects;
      globalApi.post("pilot/update", row).then((res) => {
        if (res && res.status?.toLowerCase() === "failed") {
          addToast("Unable to assign the pilot", "error");
          setSpinner(false);
          return;
        }
        getPilotAssignments("");
      });
      return;
    }
    setFormState({ ...formState, [type]: e });
    if (type === "surveyid") {
      getPilotAssignments(e);
    }
  };
  const handleDisableSave = () => {
    if (!formState.surveyid || formState.surveyid === "" || disableSave)
      return true;
    return false;
  };
  const handleFileDelete = () => {
    setShowDelete(true);
  };
  const onHideDelete = () => {
    setShowDelete(false);
  };
  const handleDeleteFile = () => {
    setSpinner(true);
    let url = "pilot/delete?surveyid=" + id;
    globalApi.get(url).then((res) => {
      if (res.status?.toLowerCase() === "failed") {
        addToast(errorMsg.FILE_DELETE, "error");
        setSpinner(false);
        return;
      }
      if (!showUpload) setFormState({});
      setDisableSave(true);
      setShowUpload(true);
      setSpinner(false);
      setShowDelete(false);
    });
  };
  const handleRowView = (rowData: any) => {
    globalApi
      .get("pilotupload/get?assignmentId=" + rowData.assignmentId)
      .then((resp) => {
        resp.map((res: any) => (res.kmlfile = "KML File"));
        setKmlRows(resp);
      });
    setKmlView(true);
  };
  const handleHide = () => {
    setKmlRows([]);
    setKmlView(false);
  };
  const handleAddNew = () => {
    setShowAddNew(true);
  };
  const handleCancel = () => {
    setData({});
    setShowAddNew(false);
  };
  const handleChange = (e: any, field: any) => {
    setData({ ...data, [field]: e });
  };

  const onCancelAddNew = () => {
    setFormState({});
    setShowAddNew(false);
  };
  const onSaveAddnew = () => {
    let startDate =
      data.scheduleStartDate?.getDate() +
      "/" +
      data.scheduleStartDate?.getMonth() +
      "/" +
      data.scheduleStartDate?.getFullYear();
    let endDate =
      data.scheduleEndDate?.getDate() +
      "/" +
      data.scheduleEndDate?.getMonth() +
      "/" +
      data.scheduleEndDate?.getFullYear();
    let obj = {
      survey: { connect: { surveyid: parseInt(id) } },
      division: data.division,
      location: data.location,
      scheduleStartDate: startDate,
      scheduleEndDate: endDate,
    };
    globalApi.post("pilot/add", obj).then((resp) => {
      getPilotAssignments("");
      setShowAddNew(false);
    });
  };
  const handleDownload = () => {
    globalApi.getBlobGet("common/files").then((resp) => {
      var a = document.createElement("a");
      var file = new Blob([resp.data], {
        type: "xlsx",
      });
      var fileURL = URL.createObjectURL(file);
      a.href = fileURL;
      a.target = "_blank";
      a.download = "sample_file.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  const handleDisableNext = () => {
    if (
      (pageIndex === 0 &&
        !(
          baseRows?.filter(
            (base: any) => base?.isActive && base?.style === "STREET_VIEW"
          )?.length === 1 &&
          baseRows?.filter(
            (base: any) => base?.isActive && base?.style === "SATELLITE_VIEW"
          )?.length === 1
        )) ||
      (pageIndex === 1 &&
        !enableSourceAttribute &&
        (allSources?.length === 0 ||
          (vectors?.length > 0 && (layers?.length === 0 || count > 0)))) ||
      (pageIndex === 1 &&
        enableSourceAttribute &&
        (allSources?.length === 0 ||
          propertiesCount === 0 ||
          (vectors?.length > 0 && (layers?.length === 0 || count > 0)))) ||
      (pageIndex === 2 &&
        (layers?.filter((obj: any) => obj?.vectortype === "line")?.length !==
          allLines?.length ||
          layers?.filter((obj: any) => obj?.vectortype === "circle")?.length !==
            allPoints?.length ||
          layers?.filter((obj: any) => obj?.vectortype === "fill")?.length !==
            allPolygons?.length ||
          layers?.filter((obj: any) => obj?.vectortype === "symbol")?.length !==
            allSymbols?.length))
    )
      return true;
    return false;
  };

  const columns3DSource: any = [
    {
      name: "S.No",
      cell: (row: any, idx: any) => <div>{idx + 1}</div>,
      width: "200px",
    },
    { name: "File Name", selector: "fileName" },
    {
      name: "File Link",
      selector: "fileLink",
    },
    {
      name: "Action",
      cell: (row: any) => (
        <>
          <i
            className="bx bx-edit fs-4 pointer"
            onClick={() => handleEdit3DSource(row)}
          />
          <i
            className="bx bx-trash fs-4 pointer"
            onClick={() => handleDelete3DSource(row)}
          />
        </>
      ),
    },
  ];

  const get3DSources = async () => {
    let response = await globalApi.get(`source3d/get?surveyId=${id}&projectId=${projectIdentifier}`);
    if (response?.status === "Success") {
      setSource3dData(response?.response?.findSource3d);
    } else {
      toast.error(response?.errormessage);
    }
  };

  const handleAdd3DSource = () => {
    setEditData(null);
    setAdd3dModal(true);
  };

  const handleEdit3DSource = (row: any) => {
    setEditData(row);
    setAdd3dModal(true);
  };

  const handleDelete3DSource = (row: any) => {
    setDeleteData(row);
    setDeleteModal(true);
  };

  const delete3DSource = async () => {
    let response = await globalApi.deleteApi(
      `source3d/delete/${deleteData?.id}`
    );
    if (response?.status === "Success") {
      toast?.success("3D Source deleted successfully")
      get3DSources()
      setDeleteModal(false);
    } else {
      toast?.error(response?.errormessage);
    }
  };
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={delete3DSource}
        />
      )}
      <Loader loader={spinner} />
      <div className="mt-3 px-3">
        <ToastContainer />
        <div className="m-b-30">
          <Breadcrum />
        </div>
        <div className="m-b-20 d-flex justify-content-center">
          <span className="m-r-20">
            <label className="m-r-10 bold">Project: </label>
            {project.projectName}
          </span>
          <span>
            <label className="m-r-10 bold">Survey: </label>
            {survey.surveyName}
          </span>
        </div>
        {pageIndex === 1 && (
          <>
            {allSources?.length === 0 && (
              <div className="display-flex flex-end mb-2">
                <div className="display-flex infoMessage">
                  <div className="p-2 display-flex">
                    <span className="display-flex BreadcrumHome">
                      <i className="pi pi-info-circle pl-1 pr-2" />
                      <span>Please Add Sources</span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className="row no-gutters border-top">
          <div className="col-12 steps-container border-top-bottom">
            {steps.map((step, index) => {
              let digitContainerStyle = "steps";
              let textColor = "inActive-txt-color p-l-10";
              if (index === pageIndex) {
                digitContainerStyle = digitContainerStyle + " activePage";
                textColor = "active-txt-color p-l-10";
              }
              let checkCondition =
                allSources?.length === 0 ||
                (vectors?.length > 0 && (layers?.length === 0 || count > 0)) ||
                (allSources?.length !== 0 &&
                  allSources?.length === rasters?.length);
              return (
                <div
                  key={index}
                  className={
                    "col-4 p-l-10 left pointer border-right" +
                    ((index === 1 &&
                      !(
                        baseRows?.filter(
                          (base: any) =>
                            base?.isActive && base?.style === "STREET_VIEW"
                        )?.length === 1 &&
                        baseRows?.filter(
                          (base: any) =>
                            base?.isActive && base?.style === "SATELLITE_VIEW"
                        )?.length === 1
                      )) ||
                    (index === 2 && checkCondition)
                      ? " disabled"
                      : "")
                  }
                  onClick={() => onStepClick(index)}
                >
                  <span className={digitContainerStyle + " m-r-10"}>
                    {index + 1}
                  </span>
                  <span className={textColor}>{step.label}</span>
                </div>
              );
            })}
          </div>
          <div className="col-12 white-background-color overflow h-100">
            {steps[pageIndex]["label"] === "Base Map" && (
              <>
                <div className="text-right mt-3 mb-3">
                  <Button
                    onClick={() => handleAdd("Add Base Style")}
                    className="btn-primary"
                  >
                    <i className="pi pi-plus-circle m-r-10" />
                    Add Base Style
                  </Button>
                </div>
                <PrimaryTable
                  columns={Projects.BaseMap}
                  rows={baseRows}
                  onClickSwitch={handleMap}
                  handleRowEdit={(rowData: any) =>
                    handleEdit(rowData, "BaseMap")
                  }
                  onClickDelete={handleDelete}
                  lazyLoad
                  onPageChange={(e: any) => onPageChange(e)}
                  searchRecord={searchRecord}
                  pageRows={pageRows}
                  count={skip}
                  paginator={rows?.length > 0 ? true : false}
                  filterKeys={searchFieldKey}
                  totalRecords={baseCount}
                  first={first}
                />
              </>
            )}
            {steps[pageIndex]["label"] === "Layers" && (
              <Accordion
                activeIndex={activeIndex}
                onTabChange={(e) => handleTabChange(e, "layers")}
                className="mt-3"
              >
                <AccordionTab header="Overlay Sources">
                  {layersComponent("Source")}
                </AccordionTab>
                <AccordionTab header="3D Source Layer">
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        className="btn-primary"
                        onClick={handleAdd3DSource}
                      >
                        <i className="pi pi-plus-circle m-r-10" /> Add 3D Source
                      </Button>
                    </Col>
                  </Row>
                  <DataTable columns={columns3DSource} data={source3dData} fixedHeader={true} fixedHeaderScrollHeight="400px"  />
                </AccordionTab>
              </Accordion>
            )}

            {steps[pageIndex]["label"] === "Assign Pilot" &&
              (showUpload ? (
                <>
                  <div className="row">
                    <div className="col-lg-4 col-md-3 col-sm-6 col-4">
                      <div className="row">
                        <div className="col-3">
                          Select Survey
                          <span className="mandatory"> *</span>
                        </div>
                        <div className="col-9">
                          <Dropdown
                            value={formState.surveyid}
                            options={surveys}
                            optionLabel="surveyname"
                            optionValue="surveyid"
                            className="w-100 border ht-50"
                            disabled={!disableSave}
                            onChange={(e) =>
                              handleDropDownChange(
                                e.target.value,
                                "",
                                "surveyid"
                              )
                            }
                            placeholder="Select a Survey"
                            filter
                            filterBy="surveyname"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-2 m-t-10">
                      KML/Aerial Demarcation File
                      <span className="mandatory"> *</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 m-t-10">
                      <FileUploadDemo
                        onChaneImage={onFileUpload}
                        value={formState.imageUrl}
                        accept=".xlsx"
                        maxFileSize={5e9}
                        icon="excel"
                        disabled={!formState.surveyid}
                        handleDelete={true}
                        handleFileDelete={handleFileDelete}
                        handleDownload={handleDownload}
                      />
                    </div>
                  </div>
                  <ActionButtons
                    buttons={["save", "cancel"]}
                    onSave={handleUploadSave}
                    disabled={handleDisableSave()}
                  />
                </>
              ) : (
                <>
                  <div className="text-right m-t-10 m-b-10">
                    <Button
                      className="btn-style btn-primary"
                      onClick={handleFileDelete}
                    >
                      <i className="pi pi-trash m-r-10" /> Delete
                    </Button>
                  </div>
                  <PrimaryTable
                    rows={rows}
                    rowData={formState}
                    pilots={pilots}
                    columns={Projects.PilotList}
                    handledDropDownChange={handleDropDownChange}
                    handleRowView={handleRowView}
                    showAddNew={true}
                    handleAddNew={handleAddNew}
                  />
                </>
              ))}
          </div>
        </div>
        {showAddBase && (
          <ProjectDetailsModal
            show={showAddBase}
            handleCancel={() => setShowAddBase(false)}
            header={header}
            handleSave={handleSave}
            data={data}
            handleChange={handleFormChange}
            kind={kind}
            locations={[{ location: "Hyderabad" }, { location: "Kakinada" }]}
            sources={vectors}
            layers={layers}
            handleChange1={handleChange}
            dataTypes={dataTypesArr}
            errors={errors}
          />
        )}
        {kmlView && (
          <KmlViewModal
            rows={kmlRows}
            visible={kmlView}
            handleHide={handleHide}
          />
        )}
        {showAddNew && (
          <AddNew
            visible={showAddNew}
            onHide={handleCancel}
            formState={data}
            handleChange={handleChange}
            handleSave={onSaveAddnew}
            onCancel={onCancelAddNew}
          />
        )}
        {showDelete && (
          <DeleteAssignPilotModal
            visible={showDelete}
            onHide={onHideDelete}
            deleteFile={handleDeleteFile}
          />
        )}
      </div>
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}

      {add3dModal && (
        <Add3DSource
          show={add3dModal}
          onClose={setAdd3dModal}
          editData={editData}
          get3DSources={get3DSources}
          surveyId={id}
          projectId={projectIdentifier}
        />
      )}
    </>
  );
};

export default AddProjectDetails;
