import React, { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "../../Common/BreadCrumb";
import { Button, Card, Col, Row } from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import CorridorTable from "../../tables/CorridorTable";
import CreateCorridor from "./CreateCorridor";
import DeleteModal from "../../Common/DeleteModal";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import Pagination from "../../commons/pagination";
import { xlReportDownload } from "../../../api/dashboard.api";
import { useSelector } from "react-redux";
import { addToast } from "../../commons/common";
import { errorMsg } from "../../commons/constant";
import * as globalApi from "../../../api/global.api";
import { corridorColumns } from "../../tables/Schema/CorridorSchema";

const index = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  const navigate = useNavigate();
  let id = useParams().id;
  const location = useLocation().state;

  const [loading, setLoading] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [corridorData, setCorridorData] = useState<any>([]);
  const [mode, setMode] = useState<any>("Add Corridor");
  const [open, setOpen] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;
  const client = useSelector((state: any) => state?.client?.clientDetails);
  const [projects, setProjects] = useState<any>([]);
  const [spinner, setSpinner] = useState<boolean>(true);
  const [rows, setRows] = useState<any>([]);

  const [columns, setColumns] = useState<any>([]);
  const [selectedColumns, setSelectedColumns] = useState<any>([]);

  const handleAddCorridor = () => {
    setMode("Add Corridor");
    setOpen(true);
  };
  const handleDeleteData = () => {
    deleteCorridor(deleteData?.id);
    setDeleteData(null);
  };

  const handleDelete = (val: any) => {
    setDeleteData(val);
  };
  const handleEdit = (val: any) => {
    setEditData(val);
  };

  const getProjects = (query: string) => {
    let url = `project/get?clientId=${id}&` + query;
    globalApi
      .get(url)
      .then((data: any) => {
        if (data?.status?.toLowerCase() === "failed") {
          addToast(errorMsg.PROJECT_GET, "error");
        } else {
          setRows(data.response.rows);
          setSpinner(false);
        }
      })
      .catch((err: any) => {
        addToast(errorMsg.PROJECT_GET, "error");
        setSpinner(false);
      });
  };

  const handleDownload = async (row: any) => {
    var a = document.createElement("a");
    const response = await xlReportDownload({
      id: row?.id,
      clientId: client?.id,
      clientName: client?.clientName,
      projectId: row?.sources?.project?.id,
      projectName: row?.sources?.project?.projectName,
      sourceId: row?.sourcesId,
      sourceName: row?.sources?.sourceName,
      isClient: false,
    });
    if (!response) {
      toast.error(
        "No completed towers available to download the corridor report"
      );
      return;
    }
    a.href = response;
    a.download = response?.split("@consolidatedReport/")[1];
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getCorridor = (val: any) => {
    setLoading(true);
    api.get(`getCorridor?sourcesId=${id}&page=${val}&limit=10`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        if (res?.response?.allowedColumns?.length > 0) {
          setColumns([...res.response.allowedColumns]);
          setSelectedColumns(
            res.response.allowedColumns.map((column: any, index: any) => index)
          );
        } else {
          setColumns([...corridorColumns]);
          setSelectedColumns(corridorColumns.map((column, index) => index));
        }
        setCorridorData(res?.response?.rows);
        setPage({ ...page, count: res?.response?.totalCount });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const deleteCorridor = (id: any) => {
    setLoading(true);
    api.deleteApi(`deleteCorridor/${id}`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        getCorridor(curr);
        setLoading(false);
        toast.success("Corridor deleted successfully");
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  useEffect(() => {
    getCorridor(curr);
  }, [curr]);

  useEffect(() => {
    getCorridor(curr);
  }, []);

  const handleCheckboxChange = (e: any, val: any) => {
    let data = [...columns];
    data[val].checked = e?.target?.checked;
    setColumns(data);
    saveColumns(data);
  };

  const saveColumns = (data: any) => {
    const payload = {
      id: location?.id,
      // clientId: client?.id,
      allowedColumns: data,
    };
    api.post(`sources/update`, payload).then((res) => {
      setLoading(true);
      if (res?.status?.toLowerCase() === "success") {
        toast.success("Column updated successfully");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.errormessage);
      }
    });
  };

  return (
    <div className="mt-3 px-2 oy">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb
        title="Source"
        pageTitle={JSON.parse(prevProjectRoute)?.name}
        pageLink={JSON.parse(prevProjectRoute)?.link}
        pageTitle1={JSON.parse(prevClientRoute)?.name}
        pageLink1={JSON.parse(prevClientRoute)?.link}
      />
      <Card>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Layers">
            <Accordion activeIndex={activeIndex}>
              <AccordionTab header="Overaly Sources">
                <Row>
                  <Col>
                    <i
                      className="fa-solid fa-arrow-left primary me-2 pointer"
                      onClick={() => navigate(-1)}
                    ></i>
                    <span>
                      Source Display Name :{" "}
                      <span style={{ color: "black" }}>
                        {sessionStorage.getItem("sourceDisplayName")}
                      </span>
                    </span>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAddCorridor}>
                      Add Corridor
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <CorridorTable
                    data={corridorData}
                    handleEdit={handleEdit}
                    setOpen={setOpen}
                    handleDelete={handleDelete}
                    handleDownload={handleDownload}
                    setMode={setMode}
                    columns={columns}
                    selectedColumns={selectedColumns}
                    setSelectedColumns={setSelectedColumns}
                    handleCheckboxChange={handleCheckboxChange}
                  />

                  <Pagination
                    itemsPerPage={10}
                    count={count}
                    handlePageChange={handlePageChange}
                  />
                </Row>
              </AccordionTab>
              <AccordionTab header="Layer Management" disabled></AccordionTab>
            </Accordion>
          </TabPanel>
          <TabPanel header="Attributes" disabled></TabPanel>
        </TabView>
      </Card>
      {open && (
        <CreateCorridor
          mode={mode}
          open={open}
          setOpen={setOpen}
          editData={editData}
          handleEdit={handleEdit}
          getCorridor={getCorridor}
          getProjects={getProjects}
        />
      )}
      {deleteData !== null && (
        <DeleteModal
          show={deleteData !== null ? true : false}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteData(null)}
        />
      )}
    </div>
  );
};

export default index;
