import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tooltip } from "primereact/tooltip";
import downloadImg from "../../assets/svg-new/file-download (1) 1.svg";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as api from "../../api/global.api";

const SourceTable = ({
  data,
  handleDownload,
  handleEdit,
  setOpen,
  handleDelete,
  setMode,
  columns,
  selectedColumns,
  setSelectedColumns,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();
  const [tableColumns, setTableColumns] = useState([]);

  const corridorActions = {
    name: <span className="font-weight-bold fs-13">Actions</span>,
    sortable: false,
    cell: (row) => {
      return (
        <span>
          <Tooltip target=".custom-target-icon-delete" />
          <Tooltip target=".custom-target-icon-edit" />
          <Tooltip target=".custom-target-icon-source" />
          <i
            className="mdi mdi-transmission-tower fs-4 me-2 pointer custom-target-icon-source"
            data-pr-tooltip="Towers"
            data-pr-position="top"
            style={{ color: "green" }}
            onClick={() => {
              navigate(`/delivers/source/corridor/tower/${row.id}`);
              sessionStorage.setItem("corridorName", row.corridorName);
            }}
          ></i>
          <img
            className="text-primary fs-4 me-2 pointer custom-target-icon-download"
            src={downloadImg}
            alt="Download"
            onClick={() => handleDownload(row)}
          />
          <i
            className="bx bx-edit fs-4 me-2 pointer custom-target-icon-edit"
            data-pr-tooltip="Edit"
            data-pr-position="top"
            style={{ color: "green" }}
            onClick={() => {
              handleEdit(row);
              setMode("Edit Corridor");
              setOpen(true);
            }}
          ></i>
          <i
            className="ri-delete-bin-6-line fs-4 me-2 pointer custom-target-icon-delete"
            data-pr-tooltip="Delete"
            data-pr-position="top"
            style={{ color: "red" }}
            onClick={() => {
              handleDelete(row);
            }}
          ></i>
        </span>
      );
    },
  };

  useEffect(() => {
    createColumns();
  }, [columns]);

  const createColumns = () => {
    const data = columns?.map((ele, index) => {
      const { fieldName, selector, checked } = ele;

      if (selector === "user") {
        return {
          name: (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
            </div>
          ),
          selector: (row) =>
            row?.user?.[0]?.firstName + " " + row?.user?.[0]?.lastName,
          sortable: false,
        };
      }

      return {
        name: (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => handleCheckboxChange(e, index)}
            />
            <span className="ms-2 font-weight-bold fs-13">{fieldName}</span>
          </div>
        ),
        selector: (row) => row[selector],
        sortable: false,
      };
    });

    let finalData = [...data, corridorActions];
    setTableColumns(finalData);
  };

  return <DataTable columns={tableColumns} data={data} />;
};

export default SourceTable;
