import React, { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "../../Common/BreadCrumb";
import { Button, Card, Col, Row } from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import SourceTable from "../../tables/SourceTable";
import CreateSource from "./CreateSource";
import DeleteModal from "../../Common/DeleteModal";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import Pagination from "../../commons/pagination";

const index = () => {
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let prevClientRoute: any = sessionStorage.getItem("prevRoute");
  const id = useParams().id;

  const [loading, setLoading] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [sourceData, setSourceData] = useState<any>([]);
  const [mode, setMode] = useState<any>("Add Source");
  const [open, setOpen] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [page, setPage] = useState<any>({ count: 0, curr: 1 });
  const { count, curr } = page;

  const handleAddSource = () => {
    setMode("Add Source");
    setOpen(true);
  };

  const handleDeleteData = () => {
    deleteSource(deleteData.id);
    setDeleteData(null);
  };

  const handleDelete = (val: any) => {
    setDeleteData(val);
  };
  const handleEdit = (val: any) => {
    setEditData(val);
  };

  const getSources = (val: any) => {
    setLoading(true);
    api
      .get(`sources/find?projectId=${id}&page=${val}&limit=10`)
      .then((res: any) => {
        if (res?.status?.toLowerCase() === "success") {
          setSourceData(res?.response?.findSources);
          setPage({ ...page, count: res?.response?.totalRecord });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      });
  };

  const deleteSource = (id: any) => {
    setLoading(true);
    api.get(`sources/delete/${id}`).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        getSources(curr);
        toast.success("Source deleted successfully");
        setLoading(false);
      } else {
        toast.error(res?.errormessage);
      }
    });
  };

  const handlePageChange = (val: any) => {
    setPage({ ...page, curr: val });
  };

  useEffect(() => {
    getSources(curr);
  }, [curr]);

  useEffect(() => {
    getSources(curr);
  }, []);
  return (
    <div className="mt-3 px-2 oy">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb
        title="Source"
        pageTitle={JSON.parse(prevProjectRoute)?.name}
        pageLink={JSON.parse(prevProjectRoute)?.link}
        pageTitle1={JSON.parse(prevClientRoute)?.name}
        pageLink1={JSON.parse(prevClientRoute)?.link}
      />
      <Card>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Layers">
            <Accordion>
              <AccordionTab header="Overaly Sources">
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAddSource}>
                      Add Source
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <SourceTable
                    data={sourceData}
                    handleEdit={handleEdit}
                    setOpen={setOpen}
                    handleDelete={handleDelete}
                    setMode={setMode}
                  />
                  <Pagination
                    itemsPerPage={12}
                    count={count}
                    handlePageChange={handlePageChange}
                  />
                </Row>
              </AccordionTab>
              <AccordionTab header="Layer Management" disabled></AccordionTab>
            </Accordion>
          </TabPanel>
          <TabPanel header="Attributes" disabled></TabPanel>
        </TabView>
      </Card>
      {open && (
        <CreateSource
          mode={mode}
          open={open}
          setOpen={setOpen}
          editData={editData}
          handleEdit={handleEdit}
          getSources={getSources}
        />
      )}
      {deleteData !== null && (
        <DeleteModal
          show={deleteData !== null ? true : false}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteData(null)}
        />
      )}
    </div>
  );
};

export default index;
