import React, { useEffect, useState } from "react";
import ClientHeader from "../Dashboard/ClientHeader";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import homeicon from "../../assets/svg-new/home-icon.svg";
import towerimg from "../../assets/svg-new/towercomponentbg.png";
import fileimg from "../../assets/svg-new/pdf-icon.svg";
import videoBg from "../../assets/svg-new/videoBg.png";
import * as api from "../../api/rest.api";
import * as gapi from "../../api/global.api";
import Select from "react-select";
import classnames from "classnames";
import {
  convertDateToISO,
  flattenArray,
  getDateOnly,
} from "../Components/Utils";
import TxComponentCharts from "./TxComponentCharts";
import ComponentCard from "./ComponentCard";
import Loader from "../../components/Common/Loader";
import Lightbox from "react-image-lightbox";
import { toast } from "react-toastify";
import ImageComponent from "../../components/Common/ImageComponent";

const TxComponent = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedTower, setSelectedTower] = useState(null);
  const [towers, setTowers] = useState([]);
  const [towerData, setTowerData] = useState(null);
  const [towerImage, setTowerImage] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [Observations, setObservations] = useState([]);
  const [selectedObservation, setSelectedObservation] = useState(null);
  const [closedObs, setCloseObs] = useState([]);
  const [openObs, setOpenObs] = useState([]);
  const [filterClose, setFilterClose] = useState([]);
  const [filterOpen, setFilterOpen] = useState([]);
  const [defectData, setDefectData] = useState([0, 0]);
  const [obsValue, setObsValue] = useState([]);
  const [obsData, setObsData] = useState([]);
  // const [isThermal, setIsThermal] = useState(false);
  // const [thermalRecords, setThermalRecords] = useState([]);
  const [isLightbox, setIsLightbox] = useState(false);
  const [idx, setIdx] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [justifyPillsTab, setjustifyPillsTab] = useState("1");
  const [statusTab, setStatusTab] = useState("1");
  const [defectFiles, setDefectFiles] = useState([]);

  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };

  const statusToggle = (tab) => {
    if (statusTab !== tab) {
      setStatusTab(tab);
    }
  };

  const getObservationList = (val) => {
    let arr = val?.map((e) => {
      let data = e[1]?.map((e) => e?.observation);
      return data;
    });
    let opt = [...new Set(flattenArray(arr))];
    let options = opt?.map((e) => ({
      label: e,
      value: e,
    }));
    setObservations(options);
  };

  const handleTowerChange = (val) => {
    setSelectedTower(val);
    let towerid = val?.value;
    let data = location.records?.find((e) => e.towerNo === towerid);
    setTowerData(data);
    getAllRecords(data?.id);
    setTowerImage(
      location.tower.sharedImages?.map((e) => ({
        ...e,
        url:
          process.env.REACT_APP_UPLOAD_TYPE === "local"
            ? process.env.REACT_APP_API_URL + "view/" + e.url
            : e.url,
      }))
    );
  };

  const getAllRecords = async (id) => {
    setLoading(true);
    let videoupload = await api.getData(
      `getTowerUploads?towerId=${id}&type=video`
    );
    console.log(videoupload);
    if (videoupload?.data) {
      setVideoData(videoupload?.data?.data);
    } else {
      setVideoData([]);
    }
    let res = await api.getData(`groupByTowerComponent/${id}`);
    let img = [];
    if (res?.data) {
      let resp = Object.entries(res?.data);
      resp?.map((ele) => {
        ele[1]?.map((item) => {
          item?.createdFrom !== "Mobile Inspection" &&
            item?.towerComponentDetails?.map((e) => {
              img.push({
                url: e?.defectFile,
                fileName: ele[0],
              });
              setDefectFiles(img);
            });
        });
      });
      let open = resp?.filter((ele) => ele[1][0]?.severityClosedDate === null);
      let close = resp?.filter((ele) => ele[1][0]?.severityClosedDate !== null);
      getObservationList(open);
      setCloseObs(close);
      setFilterClose(close);
      setOpenObs(open);
      setFilterOpen(open);
    }

    let today = convertDateToISO(new Date());
    let resp = await api.getData(
      `towerDashboard?towerId=${id}&actionClosedDate=${today}&severityClosedDate=${today}`
    );
    if (resp?.data) {
      let open = resp?.data?.totalComponentByStatus?.find(
        (e) => e.actionStatus === "Open"
      );
      let close = resp?.data?.totalComponentByStatus?.find(
        (e) => e.actionStatus === "Closed"
      );
      let obs = resp?.data?.componentsByDefects?.map((e) => e.observation);
      let val = resp?.data?.componentsByDefects?.map((e) => e.count);
      setDefectData([open.count, close.count]);
      setObsValue(val);
      setObsData(obs);
    }
    setLoading(false);
  };

  const handleVideoClick = (data) => {
    navigate("/client-tx-video", {
      state: { records: location, data: data, tower: towerData },
    });
  };

  // const thermalReport = async () => {
  //   setLoading(true);
  //   let reports = await api.getData(
  //     `getThermalReportFiles?id=${towerData?.id}&fileType=report`
  //   );
  //   if (reports?.data) {
  //     setThermalRecords(reports?.data?.uploadJsonData);
  //   }
  //   setLoading(false);
  //   setIsThermal(true);
  // };

  const handleDownloadReport = (record) => {
    const link = document.createElement("a");
    link.href = record?.defectFile;
    link.target = "_blank";
    link.download = record?.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    let name = location?.tower?.towerNo;
    setSelectedTower({ label: name, value: name });
    let records = location?.records?.map((e) => {
      return {
        label: e.towerNo,
        value: e.towerNo,
      };
    });
    setTowers(records);
    getAllRecords(location?.tower?.id);
    setTowerData(location?.tower);
    setTowerImage(
      location?.tower?.sharedImages?.map((e) => ({
        ...e,
        url:
          process.env.REACT_APP_UPLOAD_TYPE === "local"
            ? process.env.REACT_APP_API_URL + "view/" + e.url
            : e.url,
      }))
    );
  }, []);

  useEffect(() => {
    if (selectedObservation !== null) {
      if (statusTab === "1") {
        let res = openObs?.map((e) => {
          let data = e[1];
          let result = data?.filter(
            (ele) => ele.observation === selectedObservation?.value
          );
          return [e[0], result];
        });
        let finalresult = res?.filter((e) => e[1].length > 0);
        setFilterOpen(finalresult);
      } else {
        let res = closedObs?.map((e) => {
          let data = e[1];
          let result = data?.filter(
            (ele) => ele.observation === selectedObservation?.value
          );
          return [e[0], result];
        });
        let finalresult = res?.filter((e) => e[1].length > 0);
        setFilterClose(finalresult);
      }
    } else {
      statusTab === "1" ? setFilterOpen(openObs) : setFilterClose(closedObs);
    }
  }, [selectedObservation]);

  const rectifiedThermal = async () => {
    setLoading(true);
    let res = await api.updateData("updateTower", {
      id: towerData?.id,
      thermalMaxStatus: "Closed",
    });
    if (res?.data) {
      getAllRecords(res?.data?.id);
      toast.success("Thermal Rectified successfully");
      // setIsThermal(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      {isLightbox && (
        <>
          {lightboxImages?.length > 1 ? (
            <Lightbox
              mainSrc={lightboxImages[idx].link || lightboxImages[idx].url}
              imageTitle={lightboxImages[idx].fileName}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
              onMovePrevRequest={() =>
                setIdx(
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                )
              }
              onMoveNextRequest={() =>
                setIdx((idx + 1) % lightboxImages?.length)
              }
              nextSrc={lightboxImages[(idx + 1) % lightboxImages?.length].link || lightboxImages[(idx + 1) % lightboxImages?.length].url}
              prevSrc={
                lightboxImages[
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                ].link || lightboxImages[
                  (idx - 1 + lightboxImages?.length) % lightboxImages?.length
                ].url
              }
            />
          ) : (
            <Lightbox
              mainSrc={lightboxImages[idx].url}
              imageTitle={lightboxImages[idx].fileName}
              imageCaption={idx + 1}
              onCloseRequest={() => setIsLightbox(false)}
            />
          )}
        </>
      )}
      <div className="content">
        <Row className="mx-3">
          <Col>
            <span className="fw-500">
              {location?.data?.project?.projectName}
            </span>
          </Col>
          <Col>
            <div className="d-flex align-items-center justify-content-end">
              <img
                src={homeicon}
                onClick={() => navigate("/client-dashboard")}
                className="me-1 pb-1 pointer"
              />
              <i className="bx bx-chevron-right fs-4"></i>
              <span
                className="text-low pointer"
                onClick={() =>
                  navigate("/client-project-dashboard", {
                    state: location.data.project,
                  })
                }
              >
                {location?.data?.project?.projectName}
              </span>
              <i className="bx bx-chevron-right fs-4"></i>
              <span
                className="text-low pointer"
                onClick={() =>
                  navigate("/client-tx-towers", { state: location.data })
                }
              >
                {location?.tower?.corridor?.corridorName}
              </span>
              <i className="bx bx-chevron-right fs-4"></i>
              <Select
                value={selectedTower}
                options={towers}
                onChange={(val) => handleTowerChange(val)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mx-3">
          <Col md={3}>
            <Card className="p-2">
              <CardBody className="details">
                <Nav pills className="nav-justified nav-tower mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: justifyPillsTab === "1",
                      })}
                      onClick={() => {
                        justifyPillsToggle("1");
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <i className="bx bx-info-circle fs-5 me-2"></i>
                        <span>Tower Details</span>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: justifyPillsTab === "2",
                      })}
                      onClick={() => {
                        justifyPillsToggle("2");
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <i className="bx bx-play-circle fs-5 me-2"></i>
                        <span>Tower Videos</span>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: justifyPillsTab === "3",
                      })}
                      onClick={() => {
                        justifyPillsToggle("3");
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <i className="bx bx-image fs-5 me-2"></i>
                        <span>Tower Images</span>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Card className="brd-none">
                  <div className="img-tx-tower">
                    <img src={towerimg} />
                    <div className="fs-14 text-center pos-inspection">
                      Inspection :{" "}
                      <span className="fw-500">
                        {" "}
                        {getDateOnly(towerData?.inspectionDate)}{" "}
                      </span>
                    </div>
                  </div>
                </Card>
                <TabContent activeTab={justifyPillsTab} className="text-muted">
                  <TabPane tabId="1" id="pill-justified-home">
                    {/* <Row>
                      <Col className="fs-14">Tower ID</Col>
                      <Col className="fs-14">{towerData?.id}</Col>
                    </Row> */}
                    <Row>
                      <Col className="fs-14">Tower Number</Col>
                      <Col className="fs-14">{towerData?.towerNo}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Line Name</Col>
                      <Col className="fs-14">{towerData?.lineName}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Tower Category</Col>
                      <Col className="fs-14">{towerData?.towerCategory}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Circuit</Col>
                      <Col className="fs-14">{towerData?.circuit}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">lat & Long</Col>
                      <Col className="fs-14">{towerData?.location}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Voltage</Col>
                      <Col className="fs-14">{towerData?.voltage}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Structure</Col>
                      <Col className="fs-14">{towerData?.structure}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Tower Type</Col>
                      <Col className="fs-14">{towerData?.towerType}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Span</Col>
                      <Col className="fs-14">{towerData?.span}</Col>
                    </Row>
                    <Row>
                      <Col className="fs-14">Angle of Deviation</Col>
                      <Col className="fs-14">
                        {towerData?.angleOfDeviation
                          ? towerData?.angleOfDeviation
                          : "-"}
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2" id="pill-justified-home">
                    {videoData?.length > 0 ? (
                      <Row className="mx-1">
                        {videoData?.map((e, idx) => (
                          <Col key={idx} md={12}>
                            <Card
                              className="p-2 brd-none pointer"
                              onClick={() => handleVideoClick(e)}
                            >
                              <Row>
                                <Col md={4}>
                                  <div className="videobgimg">
                                    <img src={videoBg} />
                                  </div>
                                </Col>
                                <Col md={8}>
                                  <div className="fs-14">{e?.fileName}</div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <div className="text-center">Videos not available</div>
                    )}
                  </TabPane>

                  <TabPane tabId="3" id="pill-justified-profile-1">
                    {towerImage?.map((item, idx) => {
                      return (
                        <Card
                          className="sharedimg pointer mx-2"
                          key={idx}
                          onClick={() => {
                            setIdx(idx);
                            setLightboxImages(towerImage);
                            setIsLightbox(true);
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <ImageComponent src={item?.link || item?.url} />
                            <div className="fs-14 fw-500 ms-3">
                              {item?.fileName}
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col md={9} className="max-height-tower">
            <TxComponentCharts
              defectData={defectData}
              obsValue={obsValue}
              obsData={obsData}
              thermalReport={() => {}}
            />
            <Row>
              <Col md={6}>
                <Nav pills className="mb-3 nav-component">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: statusTab === "1",
                      })}
                      onClick={() => {
                        statusToggle("1");
                        getObservationList(openObs);
                        setSelectedObservation(null);
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <i className="bx bx-info-circle fs-5 me-2"></i>
                        <span>Open</span>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: statusTab === "2",
                      })}
                      onClick={() => {
                        statusToggle("2");
                        getObservationList(closedObs);
                        setSelectedObservation(null);
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <i className="bx bx-play-circle fs-5 me-2"></i>
                        <span>Close</span>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <Select
                  className="w-5"
                  value={selectedObservation}
                  options={Observations}
                  onChange={(val) => setSelectedObservation(val)}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <TabContent activeTab={statusTab} className="text-muted">
                <TabPane tabId="1" id="pill-justified-home1">
                  <>
                    {filterOpen?.length > 0 ? (
                      <Row className="mb-5">
                        {filterOpen?.map((e, idx) => (
                          <Col key={idx} md={6}>
                            <ComponentCard
                              data={e}
                              setLoading={setLoading}
                              did={idx}
                              getAllRecords={getAllRecords}
                              setFilterOpen={setFilterOpen}
                              setOpenObs={setOpenObs}
                              setFilterClose={setFilterClose}
                              setCloseObs={setCloseObs}
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <div className="text-center">No Components avilable</div>
                    )}
                  </>
                </TabPane>
                <TabPane tabId="2" id="pill-justified-home1">
                  <>
                    {filterClose?.length > 0 ? (
                      <Row className="mb-5">
                        {filterClose?.map((e, idx) => (
                          <Col key={idx} md={6}>
                            <ComponentCard
                              data={e}
                              setLoading={setLoading}
                              did={idx}
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <div className="text-center">No Components available</div>
                    )}
                  </>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </div>
      {/* {isThermal && (
        <Modal isOpen={isThermal} style={{ width: "600px" }}>
          <ModalHeader className="bg-success">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="text-light ">Thermal Report</span>
              <i
                className="bx bx-x fs-3 text-light"
                onClick={() => setIsThermal(false)}
              ></i>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              {thermalRecords?.map((record) => (
                <Col md={12}>
                  <Card className="p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img src={fileimg} className="pointer me-3" />
                        <div>{record?.filename}</div>
                      </div>
                      <div
                        className="text-end"
                        onClick={() => handleDownloadReport(record)}
                      >
                        <i className="bx bx-download fs-3 pointer"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className="w-sm me-2 br-1 bg-primary"
                  onClick={rectifiedThermal}
                >
                  Mark As Rectified
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )} */}
    </React.Fragment>
  );
};

export default TxComponent;
