import React, { useEffect, useState } from "react";
import ClientHeader from "../Dashboard/ClientHeader";
import TxMap from "./TxMap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/CommonComponent/Loader";
import TxCharts from "./TxCharts";
import Select from "react-select";
import { createSelectOption } from "../Components/Utils";
import { Button } from "reactstrap";
import CorridorTable from "./CorridorTable";
import * as api from "../../api/global.api";

const ProjectDashboard = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [corridors, setCorridors] = useState([]);
  const [selectedCorridor, setSelectedCorridor] = useState(null);
  const [corridorId, setCorridorId] = useState(null);
  const [corridorData, setCorridorData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [mapView, setMapView] = useState(false);
  const [corridorView, setCorridorView] = useState(false);
  const [showTowerStatus, setShowTowerStatus] = useState(true);

  // useEffect(() => {
  //   if (selectedCorridor) {
  //     let corridor = corridorData?.find(
  //       (e) => e.corridorName === selectedCorridor.value
  //     );
  //     navigate("/client-tx-towers", {
  //       state: {
  //         project: location,
  //         corridor: corridor,
  //         sources: sourceData,
  //       },
  //     });
  //   }
  // }, [selectedCorridor]);

  const getCorridors = (val) => {
    let list = createSelectOption(val, "corridorName");
    setCorridors(list);
    setCorridorData(val);
  };

  const handleChange = (val) => {
    setSelectedCorridor(val);
  };

  useEffect(() => {
    if (selectedCorridor) {
      let corridor = corridorData?.find(
        (e) => e.corridorName === selectedCorridor.value
      );
      navigate("/client-tx-towers", {
        state: {
          project: location,
          corridor: corridor,
          sources: sourceData,
        },
      });
    }
  }, [selectedCorridor]);

  return (
    <React.Fragment>
      <div className="tx-coridor-select d-flex">
        <Button
          className="me-3 px-4 py-2"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            setMapView(!mapView);
            setCorridorView(false);
          }}
        >
          {mapView ? "Dashboard" : "Map View"}
        </Button>

        <Button
          className="me-3 px-4 py-2"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            // setCorridorView(true);
            setCorridorView(!corridorView);
            setMapView(false);
          }}
        >
          {/* Corridors */}
          {corridorView ? "Dashboard" : "Corridors"}
        </Button>

        {!mapView && !corridorView && (
          <Button
            className="me-3 px-4 py-2"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => setShowTowerStatus(!showTowerStatus)}
          >
            {showTowerStatus ? "Defect Status" : "Tower Status"}
          </Button>
        )}
      </div>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader loader={loading} />
        </div>
      )}
      <div className="content">
        {mapView && (
          <TxMap
            project={location}
            setLoading={setLoading}
            getCorridors={getCorridors}
            setSourceData={setSourceData}
            sourceData={sourceData}
          />
        )}

        {/* {!mapView && !corridorView && <TxCharts project={location} />} */}
        {!mapView && !corridorView && (
          <TxCharts project={location} showTowerStatus={showTowerStatus} />
        )}

        {corridorView && (
          <div className="full-page">
            <CorridorTable
              location={location}
              setCorridorView={setCorridorView}
              setMapView={setMapView}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectDashboard;
